/**
 * @file 規約同意
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace FormAgreeView
	 * @constructor
	 * @classdesc 規約同意
	 * @param {instance} common - Commonインスタンス
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var FormAgreeView = function (common, $e) {
		this._common = common;
		this._$e = $e;
		this._$submitBtn = $('.btn--entry');
		this._$e.on('click', this.onClick.bind(this));
	};


	/**
	 * @memberof FormAgreeView
	 * @const {string}
	 */
	FormAgreeView._classname = 'FormAgreeView';


	/**
	 * クリックのコールバック
	 *
	 * @memberof FormAgreeView
	 * @return {undefined}
	 */
	FormAgreeView.prototype.onClick = function() {
		this._common.className('FormAgreeView.prototype.onClick');
		if(this._$e.prop('checked')){
			this._$submitBtn.removeAttr('disabled');
		}else{
			this._$submitBtn.attr('disabled', 'disabled');
		}
	};


	module.exports = FormAgreeView;
})(window, document, jQuery);