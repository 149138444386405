/**
 * @file ヘッダー
 */
(function (window, document, $) {
	'use strict';
	var ApplyClass  = require('../util/_apply-class');
	var MenuView = require('../view/_menu-view');
	var MypageView = require('../view/_mypage-view');
	var MypageLinkView = require('../view/_mypage-link-view');
	var HeaderScrollView = require('../view/_header-scroll-view');

	/**
	 * ヘッダー
	 */
	var headerInitialize = function (common) {
		$(function () {
			applyClass(common);
		});
	};


	/**
	 * クラスを適用させる
	 *
	 * @return {undefined}
	 */
	var applyClass = function (common) {
		$('.menu').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(MenuView, $e, [common, $e]);
		});
		$('.mypage').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(MypageView, $e, [common, $e]);
		});
		$('span.link--mypage-subnav').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(MypageLinkView, $e, [common, $e]);
		});
		$('header').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(HeaderScrollView, $e, [common, $e]);
		});
	};


	module.exports = headerInitialize;
})(window, document, jQuery);