/**
 * @file スタティック
 */
(function (window, document, $) {
	'use strict';
	var ApplyClass  = require('../util/_apply-class');
	var FaqFilterTypeView = require('../view/_faq-filter-type-view');
	var StoreTelView = require('../view/_store-tel-view');
	var CookiePolicyView = require('../view/_cookie-policy-view');

	/**
	 * スタティック
	 */
	var staticInitialize = function (common) {
		$(function () {
			applyClass(common);
		});
	};


	/**
	 * クラスを適用させる
	 *
	 * @return {undefined}
	 */
	var applyClass = function (common) {
		$('.faqPage').each(function(idx, e){
			var $e = $(e);
			ApplyClass.apply(FaqFilterTypeView, $e, [common, $e]);
		});
		$('dd.tel').each(function(idx, e){
			var $e = $(e);
			ApplyClass.apply(StoreTelView, $e, [common, $e]);
		});
		$('#cookie-policy').each(function(idx, e){
			var $e = $(e);
			ApplyClass.apply(CookiePolicyView, $e, [common, $e]);
		});
	};


	module.exports = staticInitialize;
})(window, document, jQuery);