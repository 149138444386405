/**
 * @file 月セレクト
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace FormSelectMonthView
	 * @constructor
	 * @classdesc 月セレクト
	 * @param {instance} common - Commonインスタンス
	 * @param {instance} controller - controllerオブジェクト
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var FormSelectMonthView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e = $e;
		this._value = this._common.zeroPadding($e.attr('data-value'), 2) || null;
		this.init();
		$e.on('change', this.onChange.bind(this));
	};


	/**
	 * @memberof FormSelectMonthView
	 * @const {string}
	 */
	FormSelectMonthView._classname = 'FormSelectMonthView';


	/**
	 * init
	 *
	 * @memberof FormSelectMonthView
	 * @return {undefined}
	 */
	FormSelectMonthView.prototype.init = function() {
		this._common.className('FormSelectMonthView.prototype.init');
		var options = '';
		for(var i = 1; i <= 12; i++){
			var val = this._common.zeroPadding(i, 2);
			if(val == this._value) {
				options += '<option value="' + val + '" selected="selected">' + val + '</option>';
			}else{
				options += '<option value="' + val + '">' + val + '</option>';
			}
		}
		this._$e.html(options);
		this._controller._month = this._$e.val();
	};


	/**
	 * 月変更のコールバック
	 *
	 * @memberof FormSelectMonthView
	 * @return {undefined}
	 */
	FormSelectMonthView.prototype.onChange = function() {
		this._common.className('FormSelectMonthView.prototype.onChange');
		this._controller.changeMonth(this._$e.val());
	};


	module.exports = FormSelectMonthView;
})(window, document, jQuery);