/**
 * @file イベントmodel
 */
(function (window, document, $) {
	'use strict';
	var Listener = require('../util/_listener');
	var instance;

	/**
	 * @namespace EventModel
	 * @constructor
	 * @classdesc イベントmodel
	 * @param {object} common - common object
	 *
	 * Singleton
	 */
	var EventModel = function(common) {
		if (instance) {
			return instance;
		}
		this._common = common;
		this._listener = new Listener();

		this._jqXHR = null;
		this._connection = false;
		this._data = null;
		this._message = null;
		this._code = null;
		this._$token = $('.ftctk');

		instance = this;
		return instance;
	};


	/**
	 * @memberof EventModel
	 * @const {string}
	 */
	EventModel._className = 'Application';


	/**
	 * レビューを取得する
	 *
	 * @memberof EventModel
	 * @param {number} page - ページ
	 * @param {number} count - 取得件数
	 * @return {undefined}
	 */
	EventModel.prototype.getReviews = function(page, count) {
		if(this._connection) return;
		this._common.className('EventModel.prototype.getReviews');
		this._connection = true;
		var params = {};
		var formData = {
			event_id: $('#event_id').val(),
			page	: parseInt(page),
			count	: parseInt(count)
		};
		var tokenName = this._$token.attr('name');
		var tokenValue = this._$token.val();
		formData[tokenName] = tokenValue;
		var url = window.wiz.const.API_URL.REVIEW_MORE;
		this._common.log(url);
		params['data'] = formData;
		params['method'] = 'POST';
		params['dataType'] = 'json';
		params['url'] = url;
		this._common.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onGetReviewsAjaxComplete.bind(this));

	};


	/**
	 * レビュー取得 ajax 通信が終わったときに呼び出されるコールバック
	 *
	 * @memberof EventModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	EventModel.prototype.onGetReviewsAjaxComplete = function(data, textStatus) {
		this._common.className('TrAccountModel.prototype.onGetReviewsAjaxComplete');
		this._data = data.result;
		this._code = data.code;
		this._message = data.message;
		this._common.log(this._code, this._message);
		var csrf = data.result.token;
		$('.ftctk').val(csrf);
		if(textStatus === 'success'){
			if(this._code !== 0){
				this._connection = false;
				this._listener.trigger('error');
			}else{
				this._connection = false;
				this._listener.trigger('getReviewsComplete');
			}
		}else{
			this._connection = false;
			this._code = 9999;
			this._message = '通信エラーが発生しました。<br>再度お試しください。';
			this._listener.trigger('error');
			this._common.log('ajax error (review):' + textStatus);
		}
		this._jqXHR = null;
	};


	/**
	 * イベントカードを取得する
	 *
	 * @memberof EventModel
	 * @param {number} page - ページ
	 * @param {number} count - 取得件数
	 * @return {undefined}
	 */
	EventModel.prototype.getEventcards = function(page, count) {
		if(this._connection) return;
		this._common.className('EventModel.prototype.getEventcards');
		var prefecture = $('.filter--prefecture').val();
		var status = $('.filter--status').val();
		this._connection = true;
		var params = {};
		var formData = {
			page	: parseInt(page),
			count	: parseInt(count),
			pid		: prefecture,
			st		: status
		};
		var tokenName = this._$token.attr('name');
		var tokenValue = this._$token.val();
		formData[tokenName] = tokenValue;
		var url = window.wiz.const.API_URL.EVENTCARD_MORE;
		this._common.log(url);
		params['data'] = formData;
		params['method'] = 'POST';
		params['dataType'] = 'json';
		params['url'] = url;
		this._common.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onGetEventcardsAjaxComplete.bind(this));

	};


	/**
	 * イベントカード取得 ajax 通信が終わったときに呼び出されるコールバック
	 *
	 * @memberof EventModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	EventModel.prototype.onGetEventcardsAjaxComplete = function(data, textStatus) {
		this._common.className('TrAccountModel.prototype.onGetEventcardsAjaxComplete');
		this._data = data.result;
		this._code = data.code;
		this._message = data.message;
		this._common.log(this._code, this._message);
		var csrf = data.result.token;
		$('.ftctk').val(csrf);
		if(textStatus === 'success'){
			if(this._code !== 0){
				this._connection = false;
				this._listener.trigger('error');
				this._common.hideLoading();
				this._common.showErrorModal('通信エラー', this._message);
			}else{
				this._connection = false;
				this._listener.trigger('getEventcardsComplete');
			}
		}else{
			this._connection = false;
			this._code = 9999;
			this._message = '通信エラーが発生しました。<br>再度お試しください。';
			this._common.log('ajax error (event card):' + textStatus);
			this._listener.trigger('error');
		}
		this._jqXHR = null;
	};


	/**
	 * listener on
	 *
	 * @memberof EventModel
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	EventModel.prototype.on = function(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listner off
	 *
	 * @memberof EventModel
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	EventModel.prototype.off = function(name, fn) {
		this._listener.off(name, fn);
	};


	module.exports = EventModel;
})(window, document, jQuery);