/**
 * @file パスワード変更エリア
 */
(function (window, document, $) {
	'use strict';
	var ApplyClass = require ('../util/_apply-class');
	var FormValidateView = require('../view/_form-validate-view');


	/**
	 * @namespace FormPasswordChangeView
	 * @constructor
	 * @classdesc パスワード変更エリア
	 * @param {instance} common - Commonインスタンス
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var FormPasswordChangeView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e = $e;
		this._$area = $('.change-password-area');
		this._$password = $('#password');
		this._$password2 = $('#password2');
		this._$e.on('click', this.onClick.bind(this));
	};


	/**
	 * @memberof FormPasswordChangeView
	 * @const {string}
	 */
	FormPasswordChangeView._classname = 'FormPasswordChangeView';


	/**
	 * clickのコールバック
	 *
	 * @memberof FormPasswordChangeView
	 * @return {undefined}
	 */
	FormPasswordChangeView.prototype.onClick = function() {
		this._common.className('FormPasswordChangeView.prototype.onClick');
		if(this._$e.hasClass('open')){
			this._$e.removeClass('open');
			this._$area.slideUp();
			this._$password.removeClass('validate').removeClass('error');
			this._$password2.removeClass('validate').removeClass('error');
			$('.error-tip--password').removeClass('show').find('.error-tip-inner').html('');
			$('.error-tip--password2').removeClass('show').find('.error-tip-inner').html('');
		}else{
			this._$e.addClass('open');
			this._$area.slideDown();
			this._$password.addClass('validate');
			this._$password2.addClass('validate');
			ApplyClass.apply(FormValidateView, this._$password, [this._common, this._controller, this._$password]);
			ApplyClass.apply(FormValidateView, this._$password2, [this._common, this._controller, this._$password2]);
		}
	};


	module.exports = FormPasswordChangeView;
})(window, document, jQuery);