/**
 * @file フッター
 */
(function (window, document, $) {
	'use strict';
	var ApplyClass  = require('../util/_apply-class');
	var FooterSubMenuView = require('../view/_footer-sub-menu-view');

	/**
	 * フッター
	 */
	var footerInitialize = function (common) {
		$(function () {
			applyClass(common);
		});
	};


	/**
	 * クラスを適用させる
	 *
	 * @return {undefined}
	 */
	var applyClass = function (common) {
		$('.list__item.inner > a').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(FooterSubMenuView, $e, [common, $e]);
		});
	};


	module.exports = footerInitialize;
})(window, document, jQuery);