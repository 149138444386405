/**
 * @file ActivityMOREボタン
 */
(function (window, document, $) {
	'use strict';
	var Template = require('../template/_activity-template');


	/**
	 * @namespace ClubBtnActivityMoreView
	 * @constructor
	 * @classdesc ActivityMOREボタン
	 * @param {instance} common - Commonインスタンス
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var ClubBtnActivityMoreView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e = $e;

		this._clicked = false;
		this._page = $e.attr('data-page');
		this._count = $e.attr('data-count');

		this._template = new Template(common);

		this._$e.on('click', this.onClick.bind(this));
		this._controller.on('getActivitiesComplete', this.onGetActivitiesComplete.bind(this));
		this._controller.on('error', this.onError.bind(this));
	};


	/**
	 * @memberof ClubBtnActivityMoreView
	 * @const {string}
	 */
	ClubBtnActivityMoreView._classname = 'ClubBtnActivityMoreView';


	/**
	 * クリックのコールバック
	 *
	 * @memberof ClubBtnActivityMoreView
	 * @return {undefined}
	 */
	ClubBtnActivityMoreView.prototype.onClick = function() {
		if(this._clicked) return;
		this._common.className('ClubBtnActivityMoreView.prototype.onClick');
		this._clicked = true;
		this._common.showLoading();
		this._page ++;
		this._controller.getActivities(this._page, this._count);
	};


	/**
	 * Activity取得完了のコールバック
	 *
	 * @memberof ClubBtnActivityMoreView
	 * @return {undefined}
	 */
	ClubBtnActivityMoreView.prototype.onGetActivitiesComplete = function() {
		this._common.className('ClubBtnActivityMoreView.prototype.onGetReviewsComplete');
		// データのセット
		var data = this._controller._model._data.list;
		this._common.log(JSON.stringify(data));
		for(var i = 0; i < data.length; i++){
			this._common.log(JSON.stringify(data[i]));
			var activity = this._template.activity(data[i]);
			this._common.log(activity);
			$('#activity-list').append(activity);
			this._clicked = false;
			this._common.hideLoading();
		}
		// レビュー件数に応じてMOREボタンを非表示にする。
		var total = this._controller._model._data.total;
		if(this._page * this._count >= total){
			this._$e.hide();
		}
		this.appendScript();
	};


	/**
	 * js追加
	 *
	 * @memberof ClubBtnActivityMoreView
	 * @return {undefined}
	 */
	ClubBtnActivityMoreView.prototype.appendScript = function() {
		this._common.className('ClubBtnActivityMoreView.prototype.appendScript');
		$('.activity--list.add-script').each(function(){
			var obj = $(this);
			var url = $(this).removeClass('add-script').find('blockquote').attr('data-instgrm-permalink');
			if(url){
				var src = url.split('/');
				src = src[4];
				src = 'https://instagram.com/p/' + src + '/media/?size=m';
				var html =
					'<a href="' + url + '" target="_blank">' +
					'	<img src="' + src + '" alt="">' +
					'</a>';
				obj.find('.instagram__wrapper').html(html);
			}
		});
	};


	/**
	 * エラーのコールバック
	 *
	 * @memberof ClubBtnActivityMoreView
	 * @return {undefined}
	 */
	ClubBtnActivityMoreView.prototype.onError = function() {
		this._common.className('ClubBtnActivityMoreView.prototype.onError');
		this._clicked = false;
		this._common.hideLoading();
		this._common.showErrorModal('通信エラー', this._controller._model._message);
	};


	module.exports = ClubBtnActivityMoreView;
})(window, document, jQuery);