/**
 * @file 部活
 */
(function (window, document, $) {
	'use strict';
	var Listener = require('../util/_listener');
	var ClubModel = require('../model/_Club-model');
	var instance;

	/**
	 * @namespace Club
	 * @constructor
	 * @classdesc 部活
	 * @param {object} common - common object
	 *
	 * Singleton
	 */
	var Club = function(common) {
		if (instance) {
			return instance;
		}
		this._common = common;
		this._listener = new Listener();

		this._model = new ClubModel(common);
		this._model.on('getActivitiesComplete', this.onGetActivitiesComplete.bind(this));
		this._model.on('error', this.onError.bind(this));

		instance = this;
		return instance;
	};


	/**
	 * @memberof Club
	 * @const {string}
	 */
	Club._className = 'Club';


	/**
	 * Activityを取得する
	 *
	 * @memberof Club
	 * @param {number} page - ページ
	 * @param {number} count - 取得件数
	 * @return {undefined}
	 */
	Club.prototype.getActivities = function(page, count) {
		this._common.className('Club.prototype.getActivities');
		this._model.getActivities(page, count);
	};


	/**
	 * Activity取得完了後のコールバック
	 *
	 * @memberof Club
	 * @return {undefined}
	 */
	Club.prototype.onGetActivitiesComplete = function() {
		this._common.className('Club.prototype.onGetActivitiesComplete');
		this._listener.trigger('getActivitiesComplete');
	};


	/**
	 * エラーのコールバック
	 *
	 * @memberof Club
	 * @return {undefined}
	 */
	Club.prototype.onError = function() {
		this._common.className('Club.prototype.onError');
		this._listener.trigger('error');
	};


	/**
	 * listener on
	 *
	 * @memberof Club
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	Club.prototype.on = function(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listner off
	 *
	 * @memberof Club
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	Club.prototype.off = function(name, fn) {
		this._listener.off(name, fn);
	};


	module.exports = Club;
})(window, document, jQuery);