// ライブラリ
(function (window, document, $) {
	'use strict';

	// load libraries
	require('./libraries/bootstrap.min');
	//require('./libraries/pace.min');
	require('./libraries/sweetalert.min');
	require('./libraries/app');
	require('./libraries/air-datepicker');
	require('./libraries/jquery.tablesorter');
	require('./libraries/jquery.sortable');
	require('./libraries/lightbox');

	window.wiz = window.wiz || {};

	// common
	var cm = require('./util/_common');
	var common = new cm();

	// initialize　
	require('./initialize/_club-initialize')(common);
	require('./initialize/_event-initialize')(common);
	require('./initialize/_static-initialize')(common);
	require('./initialize/_footer-initialize')(common);
	require('./initialize/_form-initialize')(common);
	require('./initialize/_header-initialize')(common);
	require('./initialize/_share-initialize')(common);

})(window, document, jQuery);
