/**
 * @file シェアボタン
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace ShareBtnView
	 * @constructor
	 * @classdesc シェアボタン
	 * @param {instance} common - Commonインスタンス
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var ShareBtnView = function (common, $e) {
		this._common = common;
		this._$e = $e;
		this._url = common.getCurrentURL();
		this._type = null;
		var url = this._common.getCurrentURL();
		if(url.match(/\/event\/\d+/)){
			this._type = 'eventDetail';
		}else if(url.match(/\/bukatsu\/activity\/detail\/\d+/)){
			this._type = 'clubActivityDetail';
		}else if(url.match(/\/bukatsu\/activity\/\d+/)){
			this._type = 'clubActivityList';
		}else if(url.match(/\/bukatsu\/\d+/)){
			this._type = 'clubTop';
		}
		this._common.log(this._type);
		if(this._type === 'eventDetail'){
			this._text = 'イベント情報 | ' +　$('.text--event-title').text() + ' | フライング タイガー コペンハーゲン';
		}else if(this._type === 'clubActivityDetail'){
			this._text = 'フライング タイガー コペンハーゲン　' + $('#club_name').text() + '部 | ' + $('#activity_thema').text();
		}else if(this._type === 'clubActivityList'){
			this._text = 'フライング タイガー コペンハーゲン　' + $('#club_name').text() + '部 | 過去のアクティビティー一覧';
		}else if(this._type === 'clubTop'){
			var description = $('.text--club-description').text();
			if(description.length > 50) description = description.substring(0, 50) + '…';
			this._text = 'フライング タイガー コペンハーゲン　' + $('.text--club-name').text() + '部へようこそ！';
		}

		this._$e.on('click', this.onClick.bind(this));
	};


	/**
	 * @memberof ShareBtnView
	 * @const {string}
	 */
	ShareBtnView._classname = 'ShareBtnView';


	/**
	 * クリックのコールバック
	 *
	 * @memberof ShareBtnView
	 * @return {undefined}
	 */
	ShareBtnView.prototype.onClick = function() {
		this._common.className('ShareBtnView.prototype.onClick');
		if(this._$e.hasClass('link--facebook')){
			this.shareFacebook();
		}else if(this._$e.hasClass('link--twitter')){
			this.shareTwitter();
		}else if(this._$e.hasClass('link--line')) {
			this.shareLine();
		}
	};


	/**
	 * Facebookシェア
	 *
	 * @memberof ShareBtnView
	 * @return {undefined}
	 */
	ShareBtnView.prototype.shareFacebook = function() {
		this._common.className('ShareBtnView.prototype.shareFacebook');
		window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(this._url) + '&t=' + encodeURIComponent(this._text), 'Facebook', 'width=650, height=450, menubar=no, toolbar=no, scrollbars=yes');
	};

	/**
	 * Twitterシェア
	 *
	 * @memberof ShareBtnView
	 * @return {undefined}
	 */
	ShareBtnView.prototype.shareTwitter = function() {
		this._common.className('ShareBtnView.prototype.shareTwitter');
		window.open('https://twitter.com/share?url=' + encodeURIComponent(this._url) + '&text=' + encodeURIComponent(this._text), 'Twitter', 'width=650, height=260, menubar=no, toolbar=no, scrollbars=yes');
	};


	/**
	 * LINEシェア
	 *
	 * @memberof ShareBtnView
	 * @return {undefined}
	 */
	ShareBtnView.prototype.shareLine = function() {
		this._common.className('ShareBtnView.prototype.shareLine');
		window.open('https://timeline.line.me/social-plugin/share?url=' + encodeURIComponent(this._url), 'LINE', 'width=650, height=450, menubar=no, toolbar=no, scrollbars=yes');
	};


	module.exports = ShareBtnView;
})(window, document, jQuery);