/**
 * @file アンケート＆レビュー復帰
 */
(function (window, document, $) {
	'use strict';
	var ApplyClass  = require('../util/_apply-class');
	var Template = require('../template/_questionnaire-template');
	var FormRadioView = require('../view/_form-radio-view');
	var FormCheckboxView = require('../view/_form-checkbox-view');
	var FormValidateView = require('../view/_form-validate-view');


	/**
	 * @namespace EventQuestionnaireView
	 * @constructor
	 * @classdesc アンケート＆レビュー復帰
	 * @param {instance} common - Commonインスタンス
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var EventQuestionnaireView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e = $e;
		this._value = $e.val();
		this._template = new Template(common);
		var storageKye = location.pathname.slice(1).replace( /\//g , "-" );
		this._questionVal = JSON.parse(window.sessionStorage.getItem(storageKye));
		if(this._value && this._value !== ''){
			this._value = JSON.parse($e.val());
			if(this._questionVal && this._questionVal !== ''){
				window.sessionStorage.removeItem(storageKye);
				for(var i = 0;i < Object.keys(this._questionVal).length;i ++){
					var num = i + 1;
					this._value[i].answer = this._questionVal['q'+num].answer;
				}
			}
			this.init();
		}
	};


	/**
	 * @memberof EventQuestionnaireView
	 * @const {string}
	 */
	EventQuestionnaireView._classname = 'EventQuestionnaireView';


	/**
	 * 値復帰
	 *
	 * @memberof EventQuestionnaireView
	 * @return {undefined}
	 */
	EventQuestionnaireView.prototype.init = function() {
		this._common.className('EventQuestionnaireView.prototype.init');
		var controller = this._controller;
		var common = this._common;
		var plus = 5;
		if(this._$e.attr('name') == 'questionnaire') plus = 6;
		for(var i = 0; i < this._value.length; i++){
			var question = this._value[i];
			console.log(question);
			var qNo = i + plus;
			var block = '';
			var validate = false;
			if(question['required'] && question['required'] == 'true') validate = true;
			if(question['answer_type'] == 'input'){
				this._common.log('type is input');
				var answer = question['answer'] !== '' ? question['answer'] : '';
				block = this._template.questionInput(qNo, validate, question['question_text'], answer);
			}else if(question['answer_type'] == 'textarea'){
				this._common.log('type is textarea');
				var answer = question['answer'] !== '' ? question['answer'] : '';
				block = this._template.questionTextarea(qNo, validate, question['question_text'], answer);
			}else if(question['answer_type'] == 'select'){
				this._common.log('type is select');
				var answer = question['answer'] !== '' ? question['answer'] : '';
				block = this._template.questionSelect(qNo, validate, question['question_text'], question['answer_choices'], answer);
			}else if(question['answer_type'] == 'radio'){
				this._common.log('type is radio');
				var answer = question['answer'] !== '' ? question['answer'] : '';
				block = this._template.questionRadio(qNo, validate, question['question_text'], question['answer_choices'], answer);
			}else if(question['answer_type'] == 'checkbox'){
				this._common.log('type is checkbox');
				var answer = question['answer'] !== '' ? question['answer'] : '';
				block = this._template.questionCheckbox(qNo, validate, question['question_text'], question['answer_choices'], answer);
			}
			$('#questionnaire-area').append(block);
			var $formGroup = $('.form-group:last-child');
			if(question['answer_type'] == 'input' && validate){
				var $e = $formGroup.find('input');
				ApplyClass.apply(FormValidateView, $e, [common, controller, $e]);
			}else if(question['answer_type'] == 'textarea' && validate){
				var $e = $formGroup.find('textarea');
				ApplyClass.apply(FormValidateView, $e, [common, controller, $e]);
			}else if(question['answer_type'] == 'select' && validate){
				var $e = $formGroup.find('select');
				ApplyClass.apply(FormValidateView, $e, [common, controller, $e]);
			}else if(question['answer_type'] == 'radio') {
				$formGroup.find('input[type=radio]').each(function(idx, e){
					var $e = $(e);
					var $radio = $e.closest('.custom-control--radio');
					ApplyClass.apply(FormRadioView, $radio, [common, $radio]);
					if(validate){
						ApplyClass.apply(FormValidateView, $e, [common, controller, $e]);
					}
				});
			}else if(question['answer_type'] == 'checkbox') {
				$formGroup.find('.custom-control__input[type=checkbox]').each(function(idx, e){
					var $e = $(e);
					ApplyClass.apply(FormCheckboxView, $e, [common, $e]);
					if(validate){
						ApplyClass.apply(FormValidateView, $e, [common, controller, $e]);
					}
				});
			}
		}
	};


	module.exports = EventQuestionnaireView;
})(window, document, jQuery);