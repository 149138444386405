/**
 * @file クラブストーリーswiper
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace ClubStorySwiperView
	 * @constructor
	 * @classdesc クラブストーリーswiper
	 * @param {instance} common - Commonインスタンス
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var ClubStorySwiperView = function (common, $e) {
		this._common = common;
		this._$e = $e;
		this._swiper = null;
		this.initSwiper();
	};


	/**
	 * @memberof ClubStorySwiperView
	 * @const {string}
	 */
	ClubStorySwiperView._classname = 'ClubStorySwiperView';


	/**
	 * Swiperの初期化
	 *
	 * @memberof ClubStorySwiperView
	 * @return {undefined}
	 */
	ClubStorySwiperView.prototype.initSwiper = function() {
		this._common.className('ClubStorySwiperView.prototype.initSwiper');
		this._swiper = new Swiper(this._$e, {
			slidesPerView: 8,
			slidesPerColumn: 2,
			spaceBetween: 30,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},
			breakpoints: {
				1400: {
					slidesPerView: 7,
					spaceBetween: 30
				},
				1200: {
					slidesPerView: 6,
					spaceBetween: 30
				},
				1000: {
					slidesPerView: 5,
					spaceBetween: 30
				},
				800: {
					slidesPerView: 4,
					spaceBetween: 30
				},
				600: {
					slidesPerView: 3,
					spaceBetween: 20
				},
				400: {
					slidesPerView: 2,
					spaceBetween: 15
				}
			}
		});
	};


	module.exports = ClubStorySwiperView;
})(window, document, jQuery);