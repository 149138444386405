/**
 * @file ActivityMOREボタン
 */
(function (window, document, $) {
	'use strict';

	/**
	 * @namespace ClubIdeasView
	 * @constructor
	 * @classdesc ActivityMOREボタン
	 * @param {instance} common - Commonインスタンス
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var ClubIdeasView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e = $e;
		this._$btn = $e.find('.sr-sh-theme-btn');
		this._timer = '';

		// DOMに変更があったら
		this._$e.on('DOMSubtreeModified propertychange', this.setTimer.bind(this));
	};


	/**
	 * @memberof ClubIdeasView
	 * @const {string}
	 */
	ClubIdeasView._classname = 'ClubIdeasView';


	/**
	 * 縦長の画像を拡大する
	 *
	 * @memberof ClubIdeasView
	 * @return {undefined}
	 */
	ClubIdeasView.prototype.changeItems = function() {
		this._common.className('ClubIdeasView.prototype.changeItems');
		$('#sr-social-hub-wrapper').find('.sr-sh-item-image').each(function(index, $e) {
			var e = $($e)[0];
			var heightNum =  parseInt(e.naturalHeight);
			var widthNum =  parseInt(e.naturalWidth);
			if (heightNum < widthNum) {
				var scale = heightNum / widthNum;
				scale = 2.2 - (Math.floor(scale * 10) / 10);
				$(e).css({transform:'scale(' + scale + ')'});
			}
		})
		
	};


	/**
	 * DOMの変更後、関数を一回実行
	 *
	 * @memberof ClubIdeasView
	 * @return {undefined}
	 */
	ClubIdeasView.prototype.setTimer = function() {
		this._common.className('ClubIdeasView.prototype.setTimer');
		// DOMに変更があったらタイマーをセット　最後のタイマーが一回だけ実行される
		clearTimeout(this._timer)
		this._timer = setTimeout( this.changeItems.bind(this) , 500 );
	};


	module.exports = ClubIdeasView;
})(window, document, jQuery);