/**
 * @file フッターサブメニュー
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace FooterSubMenuView
	 * @constructor
	 * @classdesc フッターサブメニュー
	 * @param {instance} common - Commonインスタンス
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var FooterSubMenuView = function (common, $e) {
		this._common = common;
		this._$e = $e;
		this._$list = $e.parent('li');
		this._$listInner = this._$list.find('.list--sitemap-inner');
		this._href = this._$e.attr('href');
		this._$e.on('click', this.onClick.bind(this));
	};


	/**
	 * @memberof FooterSubMenuView
	 * @const {string}
	 */
	FooterSubMenuView._classname = 'FooterSubMenuView';


	/**
	 * メニューを開く
	 *
	 * @memberof FooterSubMenuView
	 * @return {undefined}
	 */
	FooterSubMenuView.prototype.onClick = function(e) {
		this._common.className('FooterSubMenuView.prototype.onClick');
		e.preventDefault();
		if(this._$e.css('display') == 'block'){
			if(this._$list.hasClass('open')){
				this._$list.removeClass('open');
				this._$listInner.slideUp();
			}else{
				this._$list.addClass('open');
				this._$listInner.slideDown();
			}
		}else{
			console.log(this._href);
			location.href = this._href;
		}
	};


	/**
	 * メニューを閉じる
	 *
	 * @memberof FooterSubMenuView
	 * @return {undefined}
	 */
	FooterSubMenuView.prototype.closeMenu = function() {
		this._common.className('FooterSubMenuView.prototype.closeMenu');
		this._$navi.animate({right: '-185px'});
	};


	module.exports = FooterSubMenuView;
})(window, document, jQuery);