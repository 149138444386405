/**
 * @file 動画iframe高さ調整
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace EventMovieIframeView
	 * @constructor
	 * @classdesc 動画iframe高さ調整
	 * @param {instance} common - Commonインスタンス
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var EventMovieIframeView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e = $e;
		this._height = 0;
		this._width = 0;
		this._timer = 0;
		this.setWidthHeight();
		$(window).on('resize', this.onResize.bind(this));
	};


	/**
	 * @memberof EventMovieIframeView
	 * @const {string}
	 */
	EventMovieIframeView._classname = 'EventMovieIframeView';


	/**
	 * iframe高さ調整
	 *
	 * @memberof EventMovieIframeView
	 * @return {undefined}
	 */
	EventMovieIframeView.prototype.setWidthHeight = function() {
		this._common.className('EventMovieIframeView.prototype.setWidthHeight');
		this._width = this._$e.width();
		console.log()
		var iframeWidth = this._$e.find('iframe').attr('width');
		var ratio = this._width / iframeWidth;
		// this._common.log(ratio);
		var iframeHeight = this._$e.find('iframe').attr('height');
		$('.movie').css('min-height', iframeHeight + 'px');
		if(this._width > iframeWidth){
			this._height = iframeHeight;
			this._width = iframeWidth;
			this._$e.width(iframeWidth);
			this._$e.height(iframeHeight);
		}else{
			this._height = iframeHeight * ratio;
			this._$e.height(this._height);
		}
		if($(window).width() > 768){
			setTimeout(this.createListHeight(),500);
		}else{
			$('.dlist--event-condition').removeAttr('style').find('dd').removeAttr('style');
		}
	};


	/**
	 * description list高さ調整
	 *
	 * @memberof EventMovieIframeView
	 * @return {undefined}
	 */
	EventMovieIframeView.prototype.createListHeight = function() {
		this._common.className('EventMovieIframeView.prototype.createListHeight');
		var totalHeight = 0;
		var iframeHeight = parseInt(this._height) - 23; // +30(margin) - 53(row height)
		$('.dlist--event-condition').each(function(){
			var dl = $(this);
			var height = dl.height();
			console.log(dl.attr('data-no') + '(before): ' + totalHeight);
			if(totalHeight > iframeHeight){
				totalHeight += height;
				dl.css('width', '100%');
				console.log(dl.attr('data-no') + ': ' + height + '/' + dl.height());
				console.log(dl.attr('data-no') + ': ' + totalHeight + '(100%)');
			}else{
				dl.css('width', '51%');
				totalHeight = totalHeight + dl.height();
				console.log(dl.attr('data-no') + ': ' + height + '/' + dl.height());
				console.log(dl.attr('data-no') + ': ' + totalHeight + '(51%)');
			}
			var width = dl.width();
			dl.find('dd').width(width - 120);
		});
	};


	/**
	 * リサイズイベントコールバック
	 *
	 * @memberof EventMovieIframeView
	 * @return {undefined}
	 */
	EventMovieIframeView.prototype.onResize = function() {
		this._common.className('EventMovieIframeView.prototype.onResize');
		if (this._timer > 0) {
			clearTimeout(this._timer);
		}
		this._timer = setTimeout(this.setWidthHeight(), 200);
	};


	module.exports = EventMovieIframeView;
})(window, document, jQuery);