/**
 * @file 部活
 */
(function (window, document, $) {
	'use strict';
	var ApplyClass  = require('../util/_apply-class');
	var Club = require('../controller/_club');
	var ClubStorySwiperView = require('../view/_club-story-swiper-view');
	var ClubCopyHashView = require('../view/_club-copy-hash-view');
	var ClubBtnActivityMoreView = require('../view/_club-btn-activity-more-view');
	var ClubIdeasView = require('../view/_club-ideas-view');


	/**
	 * 部活
	 */
	var clubInitialize = function (common) {
		$(function () {
			applyClass(common);
		});
	};


	/**
	 * クラスを適用させる
	 *
	 * @return {undefined}
	 */
	var applyClass = function (common) {
		var controller = new Club(common);
		$('.swiper-container').each(function(idx, e){
			var $e = $(e);
			ApplyClass.apply(ClubStorySwiperView, $e, [common, $e]);
		});
		$('.btn--copy-hash').each(function(idx, e){
			var $e = $(e);
			ApplyClass.apply(ClubCopyHashView, $e, [common, $e]);
		});
		$('.btn--activity-more').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(ClubBtnActivityMoreView, $e, [common, controller, $e]);
		});
		$('#sr-social-hub-wrapper').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(ClubIdeasView, $e, [common, controller, $e]);
		});
	};


	module.exports = clubInitialize;
})(window, document, jQuery);