/**
 * @file ステータスで絞り込み
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace EventFilterAreaView
	 * @constructor
	 * @classdesc エリアで絞り込み
	 * @param {instance} common - Commonインスタンス
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var EventFilterAreaView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e = $e;

		this._url = this._common.getCurrentURL();

		this._$e.on('change', this.onChange.bind(this));
	};


	/**
	 * @memberof EventFilterAreaView
	 * @const {string}
	 */
	EventFilterAreaView._classname = 'EventFilterAreaView';


	/**
	 * クリックのコールバック
	 *
	 * @memberof EventFilterAreaView
	 * @return {undefined}
	 */
	EventFilterAreaView.prototype.onChange = function() {
		this._common.className('EventFilterAreaView.prototype.onChange');
		var status = this._$e.val();
		var prefecture = $('.filter--prefecture').val();
		this._common.log('prefecture:' + prefecture);
		var conjunction = '?';
		var url = this._url;
		if(prefecture && prefecture !== ''){
			url += conjunction + 'pid=' + prefecture;
			conjunction = '&';
		}
		if(status && status !== ''){
			url += conjunction + 'st=' + status;
		}
		location.href = url;
	};



	/**
	 * レビュー取得完了のコールバック
	 *
	 * @memberof EventFilterAreaView
	 * @return {undefined}
	 */
	EventFilterAreaView.prototype.onGetReviewsComplete = function() {
		this._common.className('EventFilterAreaView.prototype.onGetReviewsComplete');
		// データのセット
		var data = this._controller._model._data.data;
		this._common.log(JSON.stringify(data));
		for(var i = 0; i < data.length; i++){
			this._common.log(JSON.stringify(data[i]));
			var review = this._template.review(data[i]);
			this._common.log(review);
			$('.list--review').append(review);
			this._clicked = false;
			this._common.hideLoading();
		}
		// レビュー件数に応じてMOREボタンを非表示にする。
		var total = this._controller._model._data.total;
		if(this._page * this._count >= total){
			this._$e.hide();
		}
	};


	module.exports = EventFilterAreaView;
})(window, document, jQuery);