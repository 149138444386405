/**
 * @file アンケート＆レビュー確認画面復帰
 */
(function (window, document, $) {
	'use strict';
	var ApplyClass  = require('../util/_apply-class');
	var Template = require('../template/_questionnaire-template');


	/**
	 * @namespace EventQuestionnaireConfirmView
	 * @constructor
	 * @classdesc アンケート＆レビュー復帰
	 * @param {instance} common - Commonインスタンス
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var EventQuestionnaireConfirmView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e = $e;
		this._value = $e.val();
		this._template = new Template(common);
		if(this._value && this._value !== ''){
			this.saveQuestion(this._value);
			this._value = JSON.parse(this._value);
			this.init();
		}
	};


	/**
	 * @memberof EventQuestionnaireConfirmView
	 * @const {string}
	 */
	EventQuestionnaireConfirmView._classname = 'EventQuestionnaireConfirmView';


	/**
	 * 値復帰
	 *
	 * @memberof EventQuestionnaireConfirmView
	 * @return {undefined}
	 */
	EventQuestionnaireConfirmView.prototype.init = function() {
		this._common.className('EventQuestionnaireConfirmView.prototype.init');
		var controller = this._controller;
		var common = this._common;
		var key, required, question, answer;
		var cnt = 5;
		var qNo = 1;
		if(this._$e.attr('name') == 'questionnaire') cnt = 6;
		var json = {};
		for(key in this._value) {
			var record = this._value[key];
			var $formGroup = this._template.confirm(cnt, record.required, record.question, record.answer);
			$('#questionnaire-area').append($formGroup);
			json['q' + qNo] = record.answer;
			cnt ++;
			qNo ++;
		}
		console.log(JSON.stringify(json));
		$('#questionnaire-confirm').val(JSON.stringify(json));
	};

	/**
	 * 値保存
	 *
	 * @memberof EventQuestionnaireConfirmView
	 * @return {undefined}
	 */
	EventQuestionnaireConfirmView.prototype.saveQuestion = function(saveval) {
		// ページURLからキー名を作成
		// 例 /event/entry/confirm/237 → event-entry-237
		var storageKye = location.pathname.slice(1).replace( /\//g , "-" ).replace( /-confirm/g , "" );
		console.log(storageKye)
		window.sessionStorage.setItem(storageKye, saveval);
	};


	module.exports = EventQuestionnaireConfirmView;
})(window, document, jQuery);