/**
 * @file 部活model
 */
(function (window, document, $) {
	'use strict';
	var Listener = require('../util/_listener');
	var instance;

	/**
	 * @namespace ClubModel
	 * @constructor
	 * @classdesc 部活model
	 * @param {object} common - common object
	 *
	 * Singleton
	 */
	var ClubModel = function(common) {
		if (instance) {
			return instance;
		}
		this._common = common;
		this._listener = new Listener();

		this._jqXHR = null;
		this._connection = false;
		this._data = null;
		this._message = null;
		this._code = null;
		this._$token = $('.ftctk');

		instance = this;
		return instance;
	};


	/**
	 * @memberof ClubModel
	 * @const {string}
	 */
	ClubModel._className = 'Application';


	/**
	 * activityを取得する
	 *
	 * @memberof ClubModel
	 * @param {number} page - ページ
	 * @param {number} count - 取得件数
	 * @return {undefined}
	 */
	ClubModel.prototype.getActivities = function(page, count) {
		if(this._connection) return;
		this._common.className('ClubModel.prototype.getActivities');
		this._connection = true;
		var params = {};
		var formData = {
			club_id	: $('#club_id').val(),
			page	: page,
			count	: count
		};
		var tokenName = this._$token.attr('name');
		var tokenValue = this._$token.val();
		formData[tokenName] = tokenValue;
		var url = window.wiz.const.API_URL.ACTIVITY_MORE;
		this._common.log(url);
		params['data'] = formData;
		params['method'] = 'POST';
		params['dataType'] = 'json';
		params['url'] = url;
		this._common.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onGetActivitiesAjaxComplete.bind(this));
	};


	/**
	 * Activity取得 ajax 通信が終わったときに呼び出されるコールバック
	 *
	 * @memberof ClubModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	ClubModel.prototype.onGetActivitiesAjaxComplete = function(data, textStatus) {
		this._common.className('TrAccountModel.prototype.onGetActivitiesAjaxComplete');
		this._data = data.result;
		this._code = data.code;
		this._message = data.message;
		this._common.log(this._code, this._message);
		var csrf = data.result.token;
		$('.ftctk').val(csrf);
		if(textStatus == 'success'){
			if(this._code != 0){
				this._connection = false;
				this._listener.trigger('error');
			}else{
				this._connection = false;
				this._listener.trigger('getActivitiesComplete');
			}
		}else{
			this._connection = false;
			this._code = 9999;
			this._message = '通信エラーが発生しました。<br>再度お試しください。';
			this._listener.trigger('error');
			this._common.log('ajax error (activity):' + textStatus);
		}
		this._jqXHR = null;
	};


	/**
	 * listener on
	 *
	 * @memberof ClubModel
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	ClubModel.prototype.on = function(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listner off
	 *
	 * @memberof ClubModel
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	ClubModel.prototype.off = function(name, fn) {
		this._listener.off(name, fn);
	};


	module.exports = ClubModel;
})(window, document, jQuery);