/**
 * @file 年セレクト
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace FormSelectYearView
	 * @constructor
	 * @classdesc 年セレクト
	 * @param {instance} common - Commonインスタンス
	 * @param {instance} controller - controllerオブジェクト
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var FormSelectYearView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e = $e;
		this._value = $e.attr('data-value') || null;
		this.init();
		$e.on('change', this.onChange.bind(this));
	};


	/**
	 * @memberof FormSelectYearView
	 * @const {string}
	 */
	FormSelectYearView._classname = 'FormSelectYearView';


	/**
	 * init
	 *
	 * @memberof FormSelectYearView
	 * @return {undefined}
	 */
	FormSelectYearView.prototype.init = function() {
		this._common.className('FormSelectYearView.prototype.init');
		var start = this._common.getCurrentYear() - 80;
		var end = this._common.getCurrentYear();
		var selected = this._common.getCurrentYear() - 35;
		if(this._value && this._value != ''){
			selected = this._value;
		}
		var options = '';
		for(var i = start; i < end; i++){
			if(i == selected) {
				options += '<option value="' + i + '" selected="selected">' + i + '</option>';
			}else{
				options += '<option value="' + i + '">' + i + '</option>';
			}
		}
		this._$e.html(options);
		this._controller._year = this._$e.val();
	};


	/**
	 * 年変更のコールバック
	 *
	 * @memberof FormSelectYearView
	 * @return {undefined}
	 */
	FormSelectYearView.prototype.onChange = function() {
		this._common.className('FormSelectYearView.prototype.onChange');
		this._controller.changeYear(this._$e.val());
	};


	module.exports = FormSelectYearView;
})(window, document, jQuery);