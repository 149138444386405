/**
 * @file イベントカードテンプレート
 */
(function (window, document, $) {
	'use strict';
	var instance;

	/**
	 * @namespace イベントカードテンプレート
	 * @constructor
	 * @classdesc EventcardTemplate
	 *
	 * Singleton
	 */
	var EventcardTemplate = function(common) {
		if (instance) {
			return instance;
		}
		this._common = common;

		instance = this;
		return instance;
	};



	/**
	 * @memberof EventcardTemplate
	 * @const {string}
	 */
	EventcardTemplate._className = 'EventcardTemplate';


	/**
	 * 質問ブロック
	 *
	 * @memberof EventcardTemplate
	 * @return {undefined}
	 */
	EventcardTemplate.prototype.eventcard = function(data) {
		this._common.className('EventcardTemplate.prototype.eventcard');
		var star;
		if(data.avg_rating < 0.5){
			star = '0';
		}else if(data.avg_rating < 1){
			star = '0.5';
		}else if(data.avg_rating < 1.5){
			star = '1';
		}else if(data.avg_rating < 2){
			star = '1.5';
		}else if(data.avg_rating < 2.5){
			star = '2';
		}else if(data.avg_rating < 3){
			star = '2.5';
		}else if(data.avg_rating < 3.5){
			star = '3';
		}else if(data.avg_rating < 4){
			star = '3.5';
		}else if(data.avg_rating < 4.5){
			star = '4';
		}else if(data.avg_rating < 5){
			star = '4.5';
		}else if(data.avg_rating == 5){
			star = '5';
		}
		if(data.avg_rating.length == 1) data.avg_rating = data.avg_rating + '.0';
		var free_text = decodeURIComponent(data.event_free_text)
			.replace(/(<([^>]+)>)/ig,'')
			.replace(/\t/ig, '')
			.replace(/\r\n/ig, '')
			.replace(/\r/, '')
			.replace(/\n/, '')
			.substring(0, 60);
		var entryType = data.entry_type;
		var entryStartDate = '', entryEndDate = '', minEventDate = '', maxEventDate = '';
		if(data.min_entry_start_date){
			entryStartDate = data.min_entry_start_date.substring(0,10).replace(/-/ig, '/');
		}
		if(data.max_entry_end_date) {
			entryEndDate = data.max_entry_end_date.substring(0, 10).replace(/-/ig, '/');
		}
		if(data.min_event_date) {
			minEventDate = data.min_event_date.substring(0, 10).replace(/-/ig, '/');
		}
		if(data.max_event_date) {
			maxEventDate = data.max_event_date.substring(0, 10).replace(/-/ig, '/');
		}
		var eventcard =
			'<li class="list__item col-12 col-sm-6 col-lg-4">' +
			'	<a class="eventcard" href="/event/' + data.event_id + '">' +
			'		<div class="eventcard__image" style="background: transparent url(' + data.event_main_image_url + ') no-repeat 50% 50%/cover;">' +
			'			<div class="eventcard__info">' +
			'				<p class="eventcard__info__title">' + data.event_title + '</p>' +
			'				<p class="eventcard__info__catch">' + data.event_catch_copy + '</p>' +
			'			</div>' +
			'		</div>' +
			'		<div class="eventcard__bottom">' +
			'			<p class="eventcard__period">';
		if(entryType === '5'){
			if(entryStartDate !== entryEndDate){
				eventcard +=
			'				<span class="eventcard__period__from">開催日：' + entryStartDate + '</span>' +
			'				<span class="eventcard__period__to">' + entryEndDate + '</span>';
			}else{
				eventcard +=
			'				<span class="eventcard__period__to">開催日：' + entryStartDate + '</span>';
			}
		}else{
			if(minEventDate !== maxEventDate){
				eventcard +=
			'				<span class="eventcard__period__from">開催日：' + minEventDate + '</span>' +
			'				<span class="eventcard__period__to">' + maxEventDate + '</span>';
			}else{
				eventcard +=
			'				<span class="eventcard__period__to">開催日：' + minEventDate + '</span>';
			}
		}
		eventcard +=
			'			</p>' +
			'			<p class="eventcard__review">';
		this._common.log(data.entry_status_string);
		if(data.event_status_string === '応募受付中') {
			if(entryType !== 4){
				eventcard +=
			'				<span class="eventcard__status eventcard__status--entry-ok">' + data.event_status_string + '</span>';
			}
		}else if(data.event_status_string === '応募期間終了'){
			if(entryType !== 4) {
				eventcard +=
			'				<span class="eventcard__status eventcard__status--entry-complete">' + data.event_status_string + '</span>';
			}
		}else if(data.event_status_string === '応募受付前'){
			if(entryType !== 4) {
				eventcard +=
			'				<span class="eventcard__status eventcard__status--before-entry">' + data.event_status_string + '</span>';
			}
		}else if(data.event_status_string === '-') {
		}else{
			eventcard +=
			'				<span class="eventcard__status eventcard__status--default">' + data.event_status_string + '</span>';
		}
		if(data.avg_rating !== '0.0') {
			eventcard +=
			'				<span class="eventcard--review review review--' + star + '"></span>' +
			'				<span class="eventcard__score">' + data.avg_rating + '</span>';
		}
			eventcard +=
			'			</p>' +
			'			<p class="eventcard__description">' + free_text + '</p>' +
			'		</div>' +
			'	</a>' +
			'</li>';
		return eventcard;
	};


	module.exports = EventcardTemplate;
})(window, document, jQuery);