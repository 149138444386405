/**
 * @file イベントレビューMOREボタン
 */
(function (window, document, $) {
	'use strict';
	var Template = require('../template/_review-template');


	/**
	 * @namespace EventBtnReviewMoreView
	 * @constructor
	 * @classdesc イベントレビューMOREボタン
	 * @param {instance} common - Commonインスタンス
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var EventBtnReviewMoreView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e = $e;

		this._clicked = false;
		this._page = $e.attr('data-page');
		this._count = $e.attr('data-count');

		this._template = new Template(common);

		this._$e.on('click', this.onClick.bind(this));
		this._controller.on('getReviewsComplete', this.onGetReviewsComplete.bind(this));
		this._controller.on('error', this.onError.bind(this));
	};


	/**
	 * @memberof EventBtnReviewMoreView
	 * @const {string}
	 */
	EventBtnReviewMoreView._classname = 'EventBtnReviewMoreView';


	/**
	 * クリックのコールバック
	 *
	 * @memberof EventBtnReviewMoreView
	 * @return {undefined}
	 */
	EventBtnReviewMoreView.prototype.onClick = function() {
		if(this._clicked) return;
		this._common.className('EventBtnReviewMoreView.prototype.onClick');
		this._clicked = true;
		this._common.showLoading();
		this._page ++;
		this._controller.getReviews(this._page, this._count);
	};


	/**
	 * レビュー取得完了のコールバック
	 *
	 * @memberof EventBtnReviewMoreView
	 * @return {undefined}
	 */
	EventBtnReviewMoreView.prototype.onGetReviewsComplete = function() {
		this._common.className('EventBtnReviewMoreView.prototype.onGetReviewsComplete');
		// データのセット
		var data = this._controller._model._data.list;
		this._common.log(JSON.stringify(data));
		for(var i = 0; i < data.length; i++){
			this._common.log(JSON.stringify(data[i]));
			var review = this._template.review(data[i]);
			this._common.log(review);
			$('.list--review').append(review);
			this._clicked = false;
			this._common.hideLoading();
		}
		// レビュー件数に応じてMOREボタンを非表示にする。
		var total = this._controller._model._data.total;
		if(this._page * this._count >= total){
			this._$e.hide();
		}
	};


	/**
	 * エラーのコールバック
	 *
	 * @memberof EventBtnReviewMoreView
	 * @return {undefined}
	 */
	EventBtnReviewMoreView.prototype.onError = function() {
		this._common.className('EventBtnReviewMoreView.prototype.onError');
		this._clicked = false;
		this._common.hideLoading();
		this._common.showErrorModal('通信エラー', this._controller._model._message);
	};


	module.exports = EventBtnReviewMoreView;
})(window, document, jQuery);