/**
 * @file クラスを要素に適用する
 */
(function(window, document, $, undefined){

	/**
	 * @namespace ApplyClass
	 * @constructor
	 * @classdesc クラスを要素に適用する
	 */
	var ApplyClass = function() {};


	/**
	 * @memberof ApplyClass
	 * @const {string}
	 */
	ApplyClass._classname = 'ApplyClass';


	/**
	 * クラスを要素に適用する
	 *
	 * memo
	 * http://stackoverflow.com/questions/1606797/use-of-apply-with-new-operator-is-this-possible/#answer-1608546
	 *
	 * @memberof ApplyClass
	 * @param {function} klass - 適用したいクラス
	 * @param {jQuery} $e - クラスを適用する jQuery オブジェクト
	 * @param {Array<Object>} args - クラスのインスタンスを生成するときに渡す引数
	 * @return {Object} instance - 生成したクラスのインスタンス
	 */
	ApplyClass.apply = function(klass, $e, args) {
		this.hasApplied(klass, $e);
		if (this.hasApplied(klass, $e)) {
			return;
		}
		var F = function() {
			return klass.apply(this, args);
		};
		F.prototype = klass.prototype;
		var instance =	new F();

		if (instance) {
			var name = klass._classname;
			$e.data(name, true);
			$e.data('_' + name, instance);
		}
		if (window.wiz.const.DISPLAY_APPLY) {
			console.log('new:' + name);
		}
		return instance;
	};


	/**
	 * 要素にクラスが適用されているか調べる
	 *
	 * @memberof ApplyClass
	 * @param {function} klass - 適用したいクラス
	 * @param {jQuery} $e - クラスを適用する jQuery オブジェクト
	 * @return {bool} - 適用されていたら true
	 */
	ApplyClass.hasApplied = function(klass, $e) {
		var name = klass._classname || klass.prototype._classname;
		if (name === undefined || name === null) {
			return false;
		}
		return $e.data(name);
	};


	module.exports = ApplyClass;
})(window, document, jQuery);
