/**
 * @file イベントカードMOREボタン
 */
(function (window, document, $) {
	'use strict';
	var Template = require('../template/_eventcard-template');


	/**
	 * @namespace EventBtnEventcardMoreView
	 * @constructor
	 * @classdesc イベントカードMOREボタン
	 * @param {instance} common - Commonインスタンス
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var EventBtnEventcardMoreView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e = $e;
		console.log('EventCardMore');
		this._clicked = false;
		this._page = $e.attr('data-page');
		this._count = $e.attr('data-count');

		this._template = new Template(common);

		this._$e.on('click', this.onClick.bind(this));
		this._controller.on('getEventcardsComplete', this.onGetEventcardsComplete.bind(this));
		this._controller.on('error', this.onError.bind(this));
	};


	/**
	 * @memberof EventBtnEventcardMoreView
	 * @const {string}
	 */
	EventBtnEventcardMoreView._classname = 'EventBtnEventcardMoreView';


	/**
	 * クリックのコールバック
	 *
	 * @memberof EventBtnEventcardMoreView
	 * @return {undefined}
	 */
	EventBtnEventcardMoreView.prototype.onClick = function() {
		if(this._clicked) return;
		this._common.className('EventBtnEventcardMoreView.prototype.onClick');
		this._clicked = true;
		this._common.showLoading();
		this._page ++;
		this._controller.getEventcards(this._page, this._count);
	};


	/**
	 * イベントカード取得完了のコールバック
	 *
	 * @memberof EventBtnEventcardMoreView
	 * @return {undefined}
	 */
	EventBtnEventcardMoreView.prototype.onGetEventcardsComplete = function() {
		this._common.className('EventBtnEventcardMoreView.prototype.onGetEventcardsComplete');
		// データのセット
		var data = this._controller._model._data.list;
		this._common.log(JSON.stringify(data));
		for(var i = 0; i < data.length; i++){
			// this._common.log(JSON.stringify(data[i]));
			var eventcard = this._template.eventcard(data[i]);
			// this._common.log(eventcard);
			$('.list--event').append(eventcard);
			this._clicked = false;
			this._common.hideLoading();
		}
		// レビュー件数に応じてMOREボタンを非表示にする。
		var total = this._controller._model._data.total;
		if(this._page * this._count >= total){
			this._$e.hide();
		}
	};


	/**
	 * エラーのコールバック
	 *
	 * @memberof EventBtnEventcardMoreView
	 * @return {undefined}
	 */
	EventBtnEventcardMoreView.prototype.onError = function() {
		this._common.className('EventBtnEventcardMoreView.prototype.onError');
		this._clicked = false;
		this._common.hideLoading();
		this._common.showErrorModal('通信エラー', this._controller._model._message);
	};


	module.exports = EventBtnEventcardMoreView;
})(window, document, jQuery);