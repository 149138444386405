/**
 * @file Form送信
 */
(function (window, document, $) {
	'use strict';


	// 個別処理ファイルの読み込み
	var Entry = require('./form/form-entry');
	var Questionnaire = require('./form/form-questionnaire');


	/**
	 * @namespace FormSubmitView
	 * @constructor
	 * @param {instance} common - Commonインスタンス
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var FormSubmitView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e = $e;
		this._form = $e.attr('data-form');
		this._$form = $('#' + this._form);
		this._process = $e.attr('data-process');
		this._timing = $e.attr('data-timing');
		this._submit = true;
		if(this._process == 'true') {
			// 個別処理ファイルのアクティベート
			switch(this._form){
				case 'form-entry':
					this._processing = new Entry(common, controller);
					break;
				case 'form-questionnaire':
					this._processing = new Questionnaire(common, controller);
					break;
			}
		}

		var that = this;
		this._$e.on('click', function(e){
			e.preventDefault();
			if(!$e.hasClass('clicked')){
				$e.addClass('clicked');
				that.onClick();
			}
		});
		this._controller.on('completePreProcessing', this.validate.bind(this));
		this._controller.on('completeValidate', this.completeValidate.bind(this));
		this._controller.on('completePostProcessing', this.addMinutes.bind(this));
		this._controller.on('submitFalse', this.onSubmitFalse.bind(this));
	};


	/**
	 * @memberof RadioToggle
	 * @const {string}
	 */
	FormSubmitView._classname = 'FormSubmitView';


	/**
	 * クリックのコールバック
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	FormSubmitView.prototype.onClick = function() {
		this._common.className('FormSubmitView.prototype.onClick');
		this._controller._errorLabel = [];
		if(this._process == 'true'){
			if(this._timing == 'pre' || this._timing == 'both') {
				this._common.log('前処理実施');
				this._processing.preProcessing(this._$form);
			}else{
				this._common.log('前処理SKIP');
				this.validate(this._$form);
			}
		}else{
			this._common.log('前処理SKIP');
			this.validate(this._$form);
		}
	};


	/**
	 * バリデーションチェック
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	FormSubmitView.prototype.validate = function() {
		this._common.className('FormSubmitView.prototype.validate');
		this._common.log('バリデーションチェック');
		this._controller.validate(this._$form);
	};


	/**
	 * バリデーション完了
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	FormSubmitView.prototype.completeValidate = function() {
		this._common.className('FormSubmitView.prototype.completeValidate');
		this._common.log('バリデーション完了');
		this._common.log('エラーチェック');
		if(this._$form.find('.validate').hasClass('is-invalid')) this._submit = false;
		if(this._submit){
			this.postProcessing();
		}else{
			this._common.showErrorModal('入力項目に誤りがあります', null, this._controller._errorLabel);
			this.initialize();
		}
	};


	/**
	 * 後処理
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	FormSubmitView.prototype.postProcessing = function() {
		this._common.className('FormSubmitView.prototype.postProcessing');
		if(this._process == 'true'){
			if(this._timing == 'post' || this._timing == 'both') {
				this._common.log('後処理実施');
				this._processing.postProcessing(this._$form);
			}else{
				this._common.log('後処理SKIP');
				this.addMinutes();
			}
		}else{
			this._common.log('後処理SKIP');
			this.addMinutes();
		}
	};


	/**
	 * 分を追加する
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	FormSubmitView.prototype.addMinutes = function() {
		this._common.className('FormSubmitView.prototype.addMinutes');
		$('input[data-time=true]').each(function(){
			var val = $(this).val();
			if(val.length === 16){
				val += ':00';
				console.log(val);
				$(this).val(val);
			}
		});
		this.submit();
	};


	/**
	 * submit
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	FormSubmitView.prototype.submit = function() {
		this._common.className('FormSubmitView.prototype.submit');
		this.initialize();
		this._common.submit(this._$form);
	};


	/**
	 * 初期化
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	FormSubmitView.prototype.initialize = function() {
		this._common.className('FormSubmitView.prototype.initialize');
		this._submit = true;
		this._controller._$form = null;
		this._$e.removeClass('clicked');
	};


	/**
	 * 送信不可にする
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	FormSubmitView.prototype.onSubmitFalse = function() {
		this._common.className('FormSubmitView.prototype.onSubmitFalse');
		this._submit = false;
	};


	module.exports = FormSubmitView;
})(window, document, jQuery);