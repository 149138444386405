/**
 * @file フォーム
 */
(function (window, document, $) {
	'use strict';
	var ApplyClass  = require('../util/_apply-class');
	var Form = require('../controller/_form');
	var FormRadioView = require('../view/_form-radio-view');
	var FormCheckboxView = require('../view/_form-checkbox-view');
	var FormFileDropView = require('../view/_form-file-drop-view');
	var FormFileView = require('../view/_form-file-view');
	var FormSelectYearView = require('../view/_form-select-year-view');
	var FormSelectMonthView = require('../view/_form-select-month-view');
	var FormSelectDayView = require('../view/_form-select-day-view');
	var FormBirthdayView = require('../view/_form-birthday-view');
	var FormSubmitView = require('../view/_form-submit-view');
	var FormValidateView = require('../view/_form-validate-view');
	var FormAgreeView = require('../view/_form-agree-view');
	var FormQuestionnaireView = require('../view/_form-questionnaire-view');
	var FormQuestionnaireConfirmView = require('../view/_form-questionnaire-confirm-view');
	var FormPasswordChangeView = require('../view/_form-password-change-view');
	var FormErrorMessageView = require('../view/_form-error-message-view');


	/**
	 * フォーム
	 */
	var formInitialize = function (common) {
		$(function () {
			applyClass(common);
		});
	};


	/**
	 * クラスを適用させる
	 *
	 * @return {undefined}
	 */
	var applyClass = function (common) {
		var controller = new Form(common);
		$('.validate').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(FormValidateView, $e, [common, controller, $e]);
		});
		$('.drop-area').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(FormFileDropView, $e, [common, controller, $e]);
		});
		$('.input-file-select').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(FormFileView, $e, [common, controller, $e]);
		});
		$('.custom-control--radio').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(FormRadioView, $e, [common, $e]);
		});
		$('.custom-control__input[type=checkbox]').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(FormCheckboxView, $e, [common, $e]);
		});
		$('select.year').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(FormSelectYearView, $e, [common, controller, $e]);
		});
		$('select.month').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(FormSelectMonthView, $e, [common, controller, $e]);
		});
		$('select.day').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(FormSelectDayView, $e, [common, controller, $e]);
		});
		$('.birthday').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(FormBirthdayView, $e, [common, controller, $e]);
		});
		$('button[type=submit]').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(FormSubmitView, $e, [common, controller, $e]);
		});
		$('.custom-control__input--agree').each(function(idx, e){
			var $e = $(e);
			ApplyClass.apply(FormAgreeView, $e, [common, $e]);
		});
		$('#questionnaire').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(FormQuestionnaireView, $e, [common, controller, $e]);
		});
		$('#questionnaire-confirm').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(FormQuestionnaireConfirmView, $e, [common, controller, $e]);
		});
		$('.btn--change-password').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(FormPasswordChangeView, $e, [common, controller, $e]);
		});
		$('.error-tip--login').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(FormErrorMessageView, $e, [common, controller, $e]);
		});
	};


	module.exports = formInitialize;
})(window, document, jQuery);