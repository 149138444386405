/**
 * @file ヘッダースクロール
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace HeaderScrollView
	 * @constructor
	 * @classdesc ヘッダースクロール
	 * @param {instance} common - Commonインスタンス
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var HeaderScrollView = function (common, $e) {
		this._common = common;
		this._$e = $e;
		//$('body').append('<div id="scroll" style="height: 20px; width: 30px; background-color: #fff; z-index: 1010; position: fixed; top: 0; left: 0; text-align: center;border: 1px solid #a4a4a4;">0</div>')
		$(window).on('scroll', this.onScroll.bind(this));
	};


	/**
	 * @memberof HeaderScrollView
	 * @const {string}
	 */
	HeaderScrollView._classname = 'HeaderScrollView';


	/**
	 * メニューを開く
	 *
	 * @memberof HeaderScrollView
	 * @return {undefined}
	 */
	HeaderScrollView.prototype.onScroll = function() {
		// this._common.className('HeaderScrollView.prototype.onScroll');
		var scroll = $(window).scrollTop();
		//$('#scroll').html(parseInt(scroll));
		if(scroll > 40){
			this._$e.addClass('fixed');
		}else{
			this._$e.removeClass('fixed');
		}
	};


	module.exports = HeaderScrollView;
})(window, document, jQuery);