/**
 * @file チェックボックス
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace FormCheckboxView
	 * @constructor
	 * @classdesc チェックボックス
	 * @param {instance} common - Commonインスタンス
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var FormCheckboxView = function (common, $e) {
		this._common = common;
		this._$e = $e;
		this._$label = $e.parent('.custom-control--checkbox');
		this._$e.on('click', this.onClick.bind(this));
	};


	/**
	 * @memberof FormCheckboxView
	 * @const {string}
	 */
	FormCheckboxView._classname = 'FormCheckboxView';


	/**
	 * クリックのコールバック
	 *
	 * @memberof FormCheckboxView
	 * @return {undefined}
	 */
	FormCheckboxView.prototype.onClick = function() {
		this._common.className('FormCheckboxView.prototype.onClick');
		if(this._$e.prop('checked')){
			this._$label.addClass('checked');
		}else{
			this._$label.removeClass('checked');
		}
	};


	module.exports = FormCheckboxView;
})(window, document, jQuery);