/**
 * @file フォーム
 */
(function (window, document, $) {
	'use strict';
	var Listener = require('../util/_listener');
	var instance;

	/**
	 * @namespace Form
	 * @constructor
	 * @classdesc フォーム
	 * @param {object} common - common object
	 *
	 * Singleton
	 */
	var Form = function(common) {
		if (instance) {
			return instance;
		}
		this._common = common;

		this._year = null;
		this._month = null;
		this._lastDay = null;
		this._errorLabel = [];

		this._listener = new Listener();

		instance = this;
		return instance;
	};


	/**
	 * @memberof Form
	 * @const {string}
	 */
	Form._className = 'Form';


	/**
	 * 前処理終了
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	Form.prototype.completePreProcessing = function($form) {
		this._common.className('Form.prototype.completePreProcessing');
		this._$form = $form;
		this._listener.trigger('completePreProcessing');
	};


	/**
	 * バリデーションチェック
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	Form.prototype.validate = function($form) {
		this._common.className('Form.prototype.validate');
		this._$form = $form;
		this._listener.trigger('validate');
		var that = this;
		setTimeout(function(){
			that._listener.trigger('completeValidate')
		},500);
	};


	/**
	 * ラベルセット
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	Form.prototype.setLabel = function(label) {
		this._common.className('Form.prototype.setLabel');
		this._errorLabel.push(label);
	};


	/**
	 * 後処理終了
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	Form.prototype.completePostProcessing = function($form) {
		this._common.className('Form.prototype.completePostProcessing');
		this._$form = $form;
		this._listener.trigger('completePostProcessing');
	};


	/**
	 * 年の変更
	 *
	 * @memberof Form
	 * @return {undefined}
	 */
	Form.prototype.changeYear = function(year) {
		this._common.className('Form.prototype.changeYear');
		this._year = year;
		this._lastDay = this._common.getLastDay(this._year, this._month);
		this._listener.trigger('changeLastDay');
	};


	/**
	 * 月の変更
	 *
	 * @memberof Form
	 * @return {undefined}
	 */
	Form.prototype.changeMonth = function(month) {
		this._common.className('Form.prototype.changeMonth');
		this._month = month;
		this._lastDay = this._common.getLastDay(this._year, this._month);
		this._listener.trigger('changeLastDay');
	};


	/**
	 * submitをfalseにする
	 *
	 * @memberof Form
	 * @return {undefined}
	 */
	Form.prototype.submitFalse = function() {
		this._common.className('Form.prototype.submitFalse');
		this._listener.trigger('submitFalse');
	};


	/**
	 * listener on
	 *
	 * @memberof Form
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	Form.prototype.on = function(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listner off
	 *
	 * @memberof Form
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	Form.prototype.off = function(name, fn) {
		this._listener.off(name, fn);
	};


	module.exports = Form;
})(window, document, jQuery);