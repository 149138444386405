/**
 * @file エリアで絞り込み
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace EventFilterPrefectureView
	 * @constructor
	 * @classdesc エリアで絞り込み
	 * @param {instance} common - Commonインスタンス
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var EventFilterPrefectureView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e = $e;

		this._url = this._common.getCurrentURL();

		this._$e.on('change', this.onChange.bind(this));
	};


	/**
	 * @memberof EventFilterPrefectureView
	 * @const {string}
	 */
	EventFilterPrefectureView._classname = 'EventFilterPrefectureView';


	/**
	 * 変更のコールバック
	 *
	 * @memberof EventFilterPrefectureView
	 * @return {undefined}
	 */
	EventFilterPrefectureView.prototype.onChange = function() {
		this._common.className('EventFilterPrefectureView.prototype.onChange');
		var prefecture = this._$e.val();
		if($('html').hasClass('storelistPage')) {
			if(prefecture === '') return;
			var pos = $('#prefecture' + prefecture).get(0).offsetTop - 100;
			window.scrollTo(0, pos);
		}else{
			var status = $('.filter--status').val();
			this._common.log('status:' + status);
			var conjunction = '?';
			var url = this._url;
			if(prefecture && prefecture !== ''){
				url += conjunction + 'pid=' + prefecture;
				conjunction = '&';
			}
			if(status && status !== ''){
				url += conjunction + 'st=' + status;
			}
			location.href = url;
		}
	};


	module.exports = EventFilterPrefectureView;
})(window, document, jQuery);