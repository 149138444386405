/**
 * @file クッキーポリシー
 */
(function (window, document, $) {
	'use strict';

	/**
	 * @namespace CookiePolicyView
	 * @constructor
	 * @classdesc クッキーポリシー
	 * @param {instance} common - Commonインスタンス
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var CookiePolicyView = function (common, $e) {
		this._common = common;
		this._$close = $e.find('.btn--policy-close');
		this._$anchor = $e.find('#move-policy');
		this._href = this._$anchor.attr('data-href');
		this._$close.on('click', this.onClickClose.bind(this));
		this._$anchor.on('click', this.onClickAnchor.bind(this));
		if(!localStorage.getItem('policy')){
			if (!document.cookie.split(';').some((item) => item.includes('policy=read'))) {
				$('#cookie-policy').addClass('show');
			}
		}
	};


	/**
	 * @memberof CookiePolicyView
	 * @const {string}
	 */
	CookiePolicyView._classname = 'CookiePolicyView';


	/**
	 * closeボタンClickのコールバック
	 *
	 * @memberof CookiePolicyView
	 * @return {undefined}
	 */
	CookiePolicyView.prototype.onClickClose = function() {
		this._common.className('CookiePolicyView.prototype.onClickClose');
		document.cookie = "policy=read; path=/"
		$('#cookie-policy').removeClass('show');
	};


	/**
	 * aタグClickのコールバック
	 *
	 * @memberof CookiePolicyView
	 * @return {undefined}
	 */
	CookiePolicyView.prototype.onClickAnchor = function() {
		localStorage.setItem('policy', 'true');
		document.cookie = "policy=read; path=/";
		location.href = this._href;
	}

	module.exports = CookiePolicyView;
})(window, document, jQuery);