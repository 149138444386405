/**
 * @file アンケートテンプレート
 */
(function (window, document, $) {
	'use strict';
	var instance;

	/**
	 * @namespace アンケートテンプレート
	 * @constructor
	 * @classdesc QuestionnaireTemplate
	 *
	 * Singleton
	 */
	var QuestionnaireTemplate = function(common) {
		if (instance) {
			return instance;
		}
		this._common = common;

		instance = this;
		return instance;
	};



	/**
	 * @memberof QuestionnaireTemplate
	 * @const {string}
	 */
	QuestionnaireTemplate._className = 'QuestionnaireTemplate';


	/**
	 * inputブロック
	 *
	 * @memberof QuestionnaireTemplate
	 * @return {undefined}
	 */
	QuestionnaireTemplate.prototype.questionInput = function(qNo, required, question_text, question_answer) {
		this._common.className('QuestionnaireTemplate.prototype.questionInput');
		question_text = question_text.replace(/\r\n/ig, "\n").replace(/\r/ig, "\n").replace(/\n/ig, '<br>').replace(/,/ig, ', ');
		question_answer = question_answer != null ? question_answer.replace(/\r\n/ig, "\n").replace(/\r/ig, "\n").replace(/\n/ig, '<br>').replace(/,/ig, ', ') : '';
		var question =
			'<div class="row validateGroup form-group" data-question-no="' + qNo + '">' +
			'	<label class="col-12 form-group__label form-group__label--questionnaire">' +
			'		<span class="fukidashi fukidashi--' + qNo + '"></span>' +
			'		<span class="questionnaire-text">' + question_text;
		if(required){
			question +=
			'			<span class="badge badge--default">必須</span>';
		}
		question +=
			'		</span>' +
			'	</label>' +
			'	<div class="col-12 col-sm-9 form-group__contents form-group__contents--questionnaire form-group__contents--additional" data-questionnaire-type="input">' +
			'		<div class="error-tip">' +
			'			<div class="error-tip-inner"></div>' +
			'		</div>';
		if(required){
			question +=
			'		<input class="form-control validate" data-validate="empty" value="'+ question_answer +'">';
		}else{
			question +=
			'		<input class="form-control validate" data-validate="" value="'+ question_answer +'">';
		}
		question +=
			'	</div>' +
			'</div>';
		return question;
	};


	/**
	 * textareaブロック
	 *
	 * @memberof QuestionnaireTemplate
	 * @return {undefined}
	 */
	QuestionnaireTemplate.prototype.questionTextarea = function(qNo, required, question_text, question_answer) {
		this._common.className('QuestionnaireTemplate.prototype.questionTextarea');
		question_text = question_text.replace(/\r\n/ig, "\n").replace(/\r/ig, "\n").replace(/\n/ig, '<br>').replace(/,/ig, ', ');
		question_answer = question_answer != null ? question_answer.replace(/\r\n/ig, "\n").replace(/\r/ig, "\n").replace(/<br>/ig, " \n").replace(/,/ig, ', ') : '';
		var question =
			'<div class="row validateGroup form-group" data-question-no="' + qNo + '">' +
			'	<label class="col-12 form-group__label form-group__label--questionnaire">' +
			'		<span class="fukidashi fukidashi--' + qNo + '"></span>' +
			'		<span class="questionnaire-text">' + question_text;
		if(required){
			question +=
			'			<span class="badge badge--default">必須</span>';
		}
		question +=
			'		</span>' +
			'	</label>' +
			'	<div class="col-12 col-sm-9 form-group__contents form-group__contents--questionnaire form-group__contents--additional" data-questionnaire-type="textarea">' +
			'		<div class="error-tip">' +
			'			<div class="error-tip-inner"></div>' +
			'		</div>';
		if(required){
			question +=
			'		<textarea class="form-control validate" data-validate="empty" rows="6">'+ question_answer +'</textarea>';
		}else{
			question +=
			'		<textarea class="form-control validate" data-validate="" rows="6">'+ question_answer +'</textarea>';
		}
		question +=
			'	</div>' +
			'</div>';
		return question;
	};


	/**
	 * selectブロック
	 *
	 * @memberof QuestionnaireTemplate
	 * @return {undefined}
	 */
	QuestionnaireTemplate.prototype.questionSelect = function(qNo, required, question_text, answer_choices, question_answer) {
		this._common.className('QuestionnaireTemplate.prototype.questionSelect');
		question_text = question_text.replace(/\r\n/ig, "\n").replace(/\r/ig, "\n").replace(/\n/ig, '<br>').replace(/,/ig, ', ');
		var question =
			'<div class="row validateGroup form-group" data-question-no="' + qNo + '">' +
			'	<label class="col-12 form-group__label form-group__label--questionnaire">' +
			'		<span class="fukidashi fukidashi--' + qNo + '"></span>' +
			'		<span class="questionnaire-text">' + question_text;
		if(required){
			question +=
			'			<span class="badge badge--default">必須</span>';
		}
		question +=
			'		</span>' +
			'	</label>' +
			'	<div class="col-12 col-sm-9 form-group__contents form-group__contents--questionnaire form-group__contents--additional" data-questionnaire-type="select">' +
			'		<div class="error-tip">' +
			'			<div class="error-tip-inner"></div>' +
			'		</div>';
		if(required){
			question +=
			'		<select class="form-control validate" data-validate="empty">' +
			'			<option value="">選択してください</option>';
		}else{
			question +=
			'		<select class="form-control validate" data-validate="">' +
			'			<option value="">選択してください</option>';
		}
		for(var j = 0; j < answer_choices.length; j++){
			var isSelected = answer_choices[j] == question_answer ? 'selected' : '';
			question +=
			'			<option value="' + answer_choices[j] + '" ' + isSelected + '>' + answer_choices[j] + '</option>';
		}
		question +=
			'		</secelt>' +
			'	</div>' +
			'</div>';
		return question;
	};


	/**
	 * radioブロック
	 *
	 * @memberof QuestionnaireTemplate
	 * @return {undefined}
	 */
	QuestionnaireTemplate.prototype.questionRadio = function(qNo, required, question_text, answer_choices, question_answer) {
		this._common.className('QuestionnaireTemplate.prototype.questionRadio');
		question_text = question_text.replace(/\r\n/ig, "\n").replace(/\r/ig, "\n").replace(/\n/ig, '<br>').replace(/,/ig, ', ');
		var question =
			'<div class="row validateGroup form-group" data-question-no="' + qNo + '">' +
			'	<label class="col-12 form-group__label form-group__label--questionnaire">' +
			'		<span class="fukidashi fukidashi--' + qNo + '"></span>' +
			'		<span class="questionnaire-text">' + question_text;
		if(required){
			question +=
			'			<span class="badge badge--default">必須</span>';
		}
		question +=
			'		</span>' +
			'	</label>' +
			'	<div class="col-12 form-group__contents form-group__contents--questionnaire form-group__contents--additional" data-questionnaire-type="radio">' +
			'		<div class="error-tip">' +
			'			<div class="error-tip-inner"></div>' +
			'		</div>' +
			'		<div class="mb10">';
		for(var j = 0; j < answer_choices.length; j++){
			var isCheckedLabel = answer_choices[j] == question_answer ? 'checked' : '';
			var isChecked = answer_choices[j] == question_answer ? 'checked="checked"' : '';
			if(j == 0){
				if(required){
					question +=
			'			<div class="entry entry--custom">' +
			'				<label class="custom-control custom-control--radio custom-control--inline ' + isCheckedLabel + '">' +
			'					<input class="custom-control__input validate" type="radio" name="q' + qNo + '" value="' + answer_choices[j] + '" data-validate="radioempty" ' + isChecked + '>' +
			'					<span class="custom-control__indicator"></span>' +
			'					<span class="custom-control__description">' + answer_choices[j] + '</span>' +
			'				</label>' +
			'			</div>';
				}else{
					question +=
			'			<div class="entry entry--custom">' +
			'				<label class="custom-control custom-control--radio custom-control--inline ' + isCheckedLabel + '">' +
			'					<input class="custom-control__input validate" type="radio" name="q' + qNo + '" value="' + answer_choices[j] + '" ' + isChecked + '>' +
			'					<span class="custom-control__indicator"></span>' +
			'					<span class="custom-control__description">' + answer_choices[j] + '</span>' +
			'				</label>' +
			'			</div>';
				}
			}else{
				question +=
			'			<div class="entry entry--custom">' +
			'			<label class="custom-control custom-control--radio custom-control--inline ' + isCheckedLabel + '">' +
			'				<input class="custom-control__input validate" type="radio" name="q' + qNo + '" value="' + answer_choices[j] + '" ' + isChecked + '>' +
			'				<span class="custom-control__indicator"></span>' +
			'				<span class="custom-control__description">' + answer_choices[j] + '</span>' +
			'			</label>' +
			'			</div>';
			}
		};
		question +=
			'		</div>' +
			'	</div>' +
			'</div>';
		return question;
	};


	/**
	 * checkboxブロック
	 *
	 * @memberof QuestionnaireTemplate
	 * @return {undefined}
	 */
	QuestionnaireTemplate.prototype.questionCheckbox = function(qNo, required, question_text, answer_choices, question_answer) {
		question_text = question_text.replace(/\r\n/ig, "\n").replace(/\r/ig, "\n").replace(/\n/ig, '<br>').replace(/,/ig, ', ');
		question_answer = question_answer != null ? question_answer.split('<br>') : question_answer;
		this._common.className('QuestionnaireTemplate.prototype.questionCheckbox');
		var question =
			'<div class="row validateGroup form-group" data-question-no="' + qNo + '">' +
			'	<label class="col-12 form-group__label form-group__label--questionnaire">' +
			'		<span class="fukidashi fukidashi--' + qNo + '"></span>' +
			'		<span class="questionnaire-text">' + question_text;
		if(required){
			question +=
			'			<span class="badge badge--default">必須</span>';
		}
		question +=
			'		</span>' +
			'	</label>' +
			'	<div class="col-12 form-group__contents form-group__contents--questionnaire form-group__contents--additional" data-questionnaire-type="checkbox">' +
			'		<div class="error-tip">' +
			'			<div class="error-tip-inner"></div>' +
			'		</div>' +
			'		<div class="mb10">';
		for(var j = 0; j < answer_choices.length; j++){
			if (question_answer instanceof Array) {
				var isCheckedLabel = question_answer.indexOf(answer_choices[j]) >= 0 ? 'checked' : '';
				var isChecked = question_answer.indexOf(answer_choices[j]) >= 0 ? 'checked="checked"' : '';
			} else {
				var isCheckedLabel = answer_choices[j] == question_answer ? 'checked' : '';
				var isChecked = answer_choices[j] == question_answer ? 'checked="checked"' : '';
			}
			if(required){
			question +=
			'			<div class="entry entry--custom">' +
			'			<label class="custom-control custom-control--checkbox custom-control--inline ' + isCheckedLabel + '">' +
			'				<input class="custom-control__input validate" type="checkbox" name="' +  qNo+ '" value="' + answer_choices[j] + '" data-validate="checkboxempty" ' + isChecked + '>' +
			'				<span class="custom-control__indicator"></span>' +
			'				<span class="custom-control__description">' + answer_choices[j] + '</span>' +
			'			</label>' +
			'			</div>';
			}else{
				question +=
			'			<div class="entry entry--custom">' +
			'			<label class="custom-control custom-control--checkbox custom-control--inline ' + isCheckedLabel + '">' +
			'				<input class="custom-control__input validate" type="checkbox" name="' +  qNo+ '" value="' + answer_choices[j] + '" ' + isChecked + '>' +
			'				<span class="custom-control__indicator"></span>' +
			'				<span class="custom-control__description">' + answer_choices[j] + '</span>' +
			'			</label>' +
			'			</div>';
			}
		};
		question +=
			'		</div>' +
			'	</div>' +
			'</div>';
		return question;
	};


	/**
	 * 確認画面
	 *
	 * @memberof QuestionnaireTemplate
	 * @return {undefined}
	 */
	QuestionnaireTemplate.prototype.confirm = function(qNo, required, question_text, answer) {
		this._common.className('QuestionnaireTemplate.prototype.confirm');
		question_text = question_text.replace(/\r\n/ig, "\n").replace(/\r/ig, "\n").replace(/\n/ig, '<br>').replace(/,/ig, ', ');
		answer = answer.replace(/\r\n/ig, "\n").replace(/\r/ig, "\n").replace(/\n/ig, '<br>').replace(/,/ig, ', ');
		var question =
			'<div class="row validateGroup form-group" data-question-no="' + qNo + '">' +
			'	<label class="col-12 mb15 form-group__label">' +
			'		<span class="fukidashi fukidashi--' + qNo + '"></span>' +
			'		<span class="questionnaire-text">' + question_text;
		if(required){
			question +=
			'			<span class="badge badge--default">必須</span>';
		}
		question +=
			'		</span>' +
			'	</label>' +
			'	<div class="col-12 form-group__contents form-group__contents--questionnaire">' +
			'		<div class="error-tip">' +
			'			<div class="error-tip-inner"></div>' +
			'		</div>' + answer +
			'	</div>' +
			'</div>';
		return question;
	};


	module.exports = QuestionnaireTemplate;
})(window, document, jQuery);