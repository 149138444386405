/**
 * @file ファイルドロップ
 */
(function (window, document, $) {
	'use strict';
	var ApplyClass  = require('../util/_apply-class');
	var FormValidateView = require('./_form-validate-view');

	/**
	 * @namespace FormFileDropView
	 * @constructor
	 * @classdesc ファイルドロップ
	 * @param {instance} common - Commonインスタンス
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var FormFileDropView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e = $e;
		var dropBox = this._$e.closest('.drop-box');
		this._$existedFile = this._$e.closest('.form-group').find('.existed-file');
		this._$uploadFile = dropBox.find('.upload-image');
		this._$deleteImg = dropBox.find('.delete-image');
		this._$fileInfo = dropBox.find('.file-information');
		this._$inputFileBox = dropBox.find('.input-file-box');
		this._$inputFile = this._$inputFileBox.find('.input-file');
		this._inputFileName = this._$inputFile.attr('name');
		this._inputFileValidate = this._$inputFile.attr('data-validate');
		this._maxMB = 10;
		this._maxSize = 1024 * 1024 * this._maxMB;
		this._orientation = 1;
		this._$e.on('dragover', this.onDragover.bind(this));
		this._$e.on('dragleave', this.onDragleave.bind(this));
		this._$e.on('drop', function(ev){
			this.onDrop(ev)
		}.bind(this));
		this._$deleteImg.on('click', this.onDeleteClick.bind(this));
		this._$inputFile.on('change', this.onChange.bind(this));
	};


	/**
	 * @memberof FormFileDropView
	 * @const {string}
	 */
	FormFileDropView._classname = 'FormFileDropView';


	/**
	 * ドラッグオーバーのコールバック
	 *
	 * @memberof FormFileDropView
	 * @return {undefined}
	 */
	FormFileDropView.prototype.onDragover = function(ev) {
		this._common.className('FormFileDropView.prototype.onDragover');
		ev = ev.originalEvent;
		ev.dataTransfer.dropEffect = 'copy';
		ev.preventDefault();
		this._$e.addClass('dragover');
	};


	/**
	 * ドラッグリーブのコールバック
	 *
	 * @memberof FormFileDropView
	 * @return {undefined}
	 */
	FormFileDropView.prototype.onDragleave = function() {
		this._common.className('FormFileDropView.prototype.onDragleave');
		this._$e.removeClass('dragover');
	};


	/**
	 * ドロップのコールバック
	 *
	 * @memberof FormFileDropView
	 * @return {undefined}
	 */
	FormFileDropView.prototype.onDrop = function(ev) {
		this._common.className('FormFileDropView.prototype.onDrop');
		console.log(ev);
		ev = ev.originalEvent;
		ev.preventDefault();
		this._$e.removeClass('dragover');
		if(ev.dataTransfer.files.length > 1) {
			this._common.showErrorModal('複数画像はドロップできません。');
			return;
		}
		var file = ev.dataTransfer.files[0];
		if (!file || file.type.indexOf('image/') < 0) {
			this._common.showErrorModal('画像ファイルではありません。');
			return;
		}
		if (file.size > this._maxSize) {
			this._common.showErrorModal('画像サイズは' + this._maxMB + 'MB以下の必要があります。');
			return;
		}
		this._$inputFile.prop('files', ev.dataTransfer.files);
		this.adjustExif(ev.dataTransfer.files);
	};


	/**
	 * 画像選択のコールバック
	 *
	 * @memberof FormFileDropView
	 * @return {undefined}
	 */
	FormFileDropView.prototype.onChange = function(ev) {
		this._common.className('FormFileDropView.prototype.onChange');
		var files = ev.target.files;
		var reader = new FileReader();
		reader.onload = this.adjustExif(files);
	};


	/**
	 * EXIF処理
	 *
	 * @memberof FormFileDropView
	 * @param {object} files - ファイルオブジェクト
	 * @return {undefined}
	 */
	FormFileDropView.prototype.adjustExif = function(files) {
		this._common.className('FormFileDropView.prototype.adjustExif');
		var file = files[0];
		var orientation = 1;
		var that = this;
		EXIF.getData(file,function(){
			orientation = EXIF.getTag(this, 'Orientation');
			setTimeout(function(){
				that.outputImage(files, orientation);
			},300);
		});
	};


	/**
	 * 画像の表示
	 *
	 * @memberof FormFileDropView
	 * @param {object} files - ファイルオブジェクト
	 * @param {string} orientation - 画像回転
	 * @return {undefined}
	 */
	FormFileDropView.prototype.outputImage = function(files, orientation) {
		this._common.className('FormFileDropView.prototype.outputImage');
		this._orientation = orientation;
		this._$fileInfo.addClass('mt10');
		var file = files[0];
		var image = new Image(200);
		image.src = URL.createObjectURL(file);
		this._$deleteImg.css('display', 'inline-block');
		this._$uploadFile
			.css('width', '200px')
			.css('height', '200px')
			.css('background', 'transparent url(' + image.src + ') no-repeat 50% 50%/cover')
			.css('border', '1px solid #999');
		this._common.log('this._orientation: ' + this._orientation);
		if(this._orientation === 6){
			this._$uploadFile.css('transform', 'rotate(90deg)');
		}else if(this._orientation === 3){
			this._$uploadFile.css('transform', 'rotate(180deg)');
		}else if(this._orientation === 8){
			this._$uploadFile.css('transform', 'rotate(-90deg)');
		}
	};


	/**
	 * 画像削除ボタンクリック
	 *
	 * @memberof FormFileDropView
	 * @return {undefined}
	 */
	FormFileDropView.prototype.onDeleteClick = function() {
		this._common.className('FormFileDropView.prototype.onClick');
		this._$fileInfo.removeClass('mt10');
		this._$deleteImg.hide();
		var inputFile = '<input class="input-file validate" type="file" value="ファイルを選択" name="' + this._inputFileName + '" data-validate="' + this._inputFileValidate + '">';
		this._$existedFile.val('');
		this._$inputFileBox.html(inputFile);
		this._$uploadFile.removeAttr('style');
		this._$inputFile = this._$inputFileBox.find('.input-file');
		this._$inputFile.on('change', this.onChange.bind(this));
		ApplyClass.apply(FormValidateView, this._$inputFile, [this._common, this._controller, this._$inputFile]);
	};

	module.exports = FormFileDropView;
})(window, document, jQuery);