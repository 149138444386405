/**
 * @file マイページメニューリンク
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace MypageView
	 * @constructor
	 * @classdesc マイページメニューリンク
	 * @param {instance} common - Commonインスタンス
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var MypageView = function (common, $e) {
		this._common = common;
		this._$e = $e;
		this._href = $e.attr('data-href');
		$e.on('click', this.onClick.bind(this));
	};


	/**
	 * @memberof MypageView
	 * @const {string}
	 */
	MypageView._classname = 'MypageView';


	/**
	 * メニューを開く
	 *
	 * @memberof MypageView
	 * @return {undefined}
	 */
	MypageView.prototype.onClick = function() {
		this._common.className('MypageView.prototype.onClick');
		location.href = this._href;
	};


	module.exports = MypageView;
})(window, document, jQuery);