/**
 * @file 日セレクト
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace FormSelectDayView
	 * @constructor
	 * @classdesc 日セレクト
	 * @param {instance} common - Commonインスタンス
	 * @param {instance} controller - controllerオブジェクト
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var FormSelectDayView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e = $e;
		this._value = this._common.zeroPadding($e.attr('data-value'), 2) || null;
		this.init();
		this._controller.on('changeLastDay', this.onChangeLastDay.bind(this));
	};


	/**
	 * @memberof FormSelectDayView
	 * @const {string}
	 */
	FormSelectDayView._classname = 'FormSelectDayView';


	/**
	 * init
	 *
	 * @memberof FormSelectDayView
	 * @return {undefined}
	 */
	FormSelectDayView.prototype.init = function() {
		this._common.className('FormSelectDayView.prototype.init');
		var options = '';
		for(var i = 1; i <= 31; i++){
			var val = this._common.zeroPadding(i, 2);
			if(val == this._value) {
				options += '<option value="' + val + '" selected="selected">' + val + '</option>';
			}else{
				options += '<option value="' + val + '">' + val + '</option>';
			}
		}
		this._$e.html(options);
	};


	/**
	 * 日付変更のコールバック
	 *
	 * @memberof FormSelectDayView
	 * @return {undefined}
	 */
	FormSelectDayView.prototype.onChangeLastDay = function() {
		this._common.className('FormSelectDayView.prototype.onChangeLastDay');
		var value = this._common.zeroPadding(this._$e.val(),2);
		var lastDay = this._controller._lastDay;
		var options = '';
		for(var i = 1; i <= lastDay; i++){
			var val = this._common.zeroPadding(i, 2);
			if(val == value) {
				options += '<option value="' + val + '" selected="selected">' + val + '</option>';
			}else{
				options += '<option value="' + val + '">' + val + '</option>';
			}
		}
		this._$e.html(options);
	};


	module.exports = FormSelectDayView;
})(window, document, jQuery);