/**
 * @file アラート閉じるボタン
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace FormErrorMessageView
	 * @constructor
	 * @classdesc アラート閉じるボタン
	 * @param {instance} common - Commonインスタンス
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var FormErrorMessageView = function (common, controller, $e) {
		this._common = common;
		this._$e = $e;
		this._$e.on('click', this.onClick.bind(this));
	};


	/**
	 * @memberof FormErrorMessageView
	 * @const {string}
	 */
	FormErrorMessageView._classname = 'FormErrorMessageView';


	/**
	 * clickのコールバック
	 *
	 * @memberof FormErrorMessageView
	 * @return {undefined}
	 */
	FormErrorMessageView.prototype.onClick = function() {
		this._common.className('FormErrorMessageView.prototype.onClick');
		this._$e.remove();
	};


	module.exports = FormErrorMessageView;
})(window, document, jQuery);