/**
 * @file バリデーションチェック
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace FormValidateView
	 * @constructor
	 * @classdesc フォームバリデーション
	 * @param {instance} common - Commonインスタンス
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var FormValidateView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e         = $e;
		this._validates  = $e.attr('data-validate');
		if (!this._validates || this._validates === '') return;
		this._element			= $e.prop('tagName').toLowerCase();
		this._type				= $e.attr('type') || null;
		this._value				= '';
		this._error				= [];
		this._setFile			= null;
		this._$form				= this._$e.closest('form');
		this._$formGroup		= this._$e.closest('.form-group');
		this._$errortip			= this._$formGroup.find('.error-tip');
		this._$errortipInner	= this._$errortip.find('.error-tip-inner');
		;
		if(this._$formGroup.attr('data-question-no') && this._$formGroup.attr('data-question-no') > 0){
			this._label = '質問' + this._$formGroup.attr('data-question-no');
		} else {
			this._label = this._$formGroup.find('.form-group__label').text().replace(/必須/ig, '').replace(/任意/ig, '').replace(/\t/ig, '').replace(/\n/ig, '');
		}
		this._errorMessage    = {
			empty				: '<p>この項目は必須です。</p>',
			nospace				: '<p>この項目にスペースは利用できません。</p>',
			nonumber			: '<p>この項目に数字は利用できません。</p>',
			nosymbol			: '<p>この項目に記号は利用できません。</p>',
			zenkaku				: '<p>この項目は全角で入力してください。</p>',
			kana				: '<p>この項目はひらがなもしくはカタカナで入力してください。</p>',
			hiragana			: '<p>この項目は全角かなで入力してください。</p>',
			katakana			: '<p>この項目は全角カナで入力してください。</p>',
			hankaku				: '<p>この項目は半角で入力してください。</p>',
			number				: '<p>この項目は半角数字で入力してください。</p>',
			alphabet           	: '<p>この項目は半角アルファベットで入力してください。</p>',
			alnum				: '<p>この項目は半角アルファベットもしくは半角数字で入力してください。</p>',
			date				: '<p>日付が不正です。</p>',
			email				: '<p>メールアドレスが不正です。</p>',
			jancode				: '<p>JANコードが不正です。</p>',
			mixPasswordNumber	: '<p>パスワードには半角数字も使う必要があります。</p>',
			mixPasswordAlphabet	: '<p>パスワードには半角英語も使う必要があります。</p>',
			birthDate			: '<p>誕生日が不正です。</p>',
			retype				: '<p class="retype">入力内容が一致しません。</p>',
			max1				: '<p>文字数は',
			max2				: '文字以下です。</p>',
			min1				: '<p>文字数は',
			min2				: '文字以上です。</p>',
			range1				: '<p>文字数は',
			range2				: '文字以上',
			range3				: '文字以下です。</p>',
			len1				: '<p>文字数は',
			len2				: '文字です。</p>',
			file				: '<p>ファイルが選択されていません。</p>',
			instagram			: '<p>アカウントの形式が不正です。</p>',
			checkboxEmpty		: '<p>少なくとも1つは選択してください</p>',
			radioEmpty			: '<p>少なくとも1つは選択してください</p>'
		};
		this._controller.on('validate', this.onValidate.bind(this));
		if ($e.hasClass('ajaxZip')) {
			this._targetPrefecture = $e.attr('data-target-prefecture');
			this._targetAddress = $e.attr('data-target-address');
			var that = this;
			$e.on('blur', function () {
				that.ajaxZip(this);
			});
		}
		this.setEvent();
	};


	/**
	 * @memberof FormValidateView
	 * @const {string}
	 */
	FormValidateView._classname = 'FormValidateView';


	/**
	 * イベントトリガーを設定する
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.setEvent = function () {
		if (this._element === 'input') {
			if (this._type === 'checkbox' || this._type === 'radio') {
				this._$e.on('click', this.doValidate.bind(this));
			} else if(this._type === 'file') {
				this._$e.on('change', this.doValidate.bind(this));
			} else {
				this._$e.on('blur', this.doValidate.bind(this));
			}
		} else if (this._element === 'select') {
			this._$e.on('change', this.doValidate.bind(this));
		} else if (this._element === 'textarea') {
			this._$e.on('blur', this.doValidate.bind(this));
		}
	};


	/**
	 * 送信ボタンクリック時のバリデーションのコールバック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.onValidate = function () {
		this._common.className('FormValidateView.prototype.onValidate');
		this.doValidate();
	};


	/**
	 * バリデーションチェックのコールバック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.doValidate = function () {
		if(this._controller._$form && this._controller._$form.attr('id') !== this._$form.attr('id')) return;
		if(this._$e.attr('id')){
			this._common.log('FormValidateView.prototype.doValidate: ' + this._$e.attr('id'));
		}else{
			this._common.log('FormValidateView.prototype.doValidate: ' + this._$e.attr('class'));
		}
		var validateArray = this._validates.split(' ');
		this.setValue();
		this._error = [];
		for (var i = 0; i < validateArray.length; i++) {
			this.validate(validateArray[i]);
			if (i === validateArray.length - 1) {
				if (this._error.length > 0) {
					this.showError();
				} else {
					this.hideError();
				}
			}
		}
	};

	/**
	 * ファイル選択のコールバック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.setFile = function () {
		this._setFile = true;
		this.hideError();
		this._common.log('file selected');
	};

	/**
	 * 値を設定する
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.setValue = function () {
		if (this._element === 'input') {
			if (this._type === 'checkbox') {
				if (this._$e.prop('checked')) {
					this._value = this._$e.val();
				} else {
					this._value = '';
				}
			} else if (this._type === 'radio') {
				var name    = this._$e.attr('name');
				this._value = $('[name=' + name + ']:checked').val();
			} else if (this._type == 'file'){
				this._value = this._$e.val();
				this._common.log('FILE: '+this._value);
			} else {
				this._value = $.trim(this._$e.val());
				this._$e.val(this._value);
			}
		} else if (this._element === 'textarea') {
			this._value = $.trim(this._$e.val());
			this._$e.val(this._value);
		} else {
			this._value = this._$e.val();
		}
	};


	/**
	 * バリデーションを実施する
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.validate = function (validate) {
		var type = validate;
		if (validate.match(/^max-/)) {
			type = 'max';
		} else if (validate.match(/^min-/)) {
			type = 'min';
		} else if (validate.match(/^range-/)) {
			type = 'range';
		} else if (validate.match(/^len-/)) {
			type = 'len';
		} else if (validate.match(/^retype-/)) {
			type = 'retype';
		} else if (validate.match(/^typebase-/)) {
			type = 'typebase';
		}
		var args = validate.split('-');
		if (this._value === '' && !type.match(/empty/)) return;
		switch (type) {
			case 'empty':
				this.checkEmpty();
				break;
			case 'nospace':
				this.checkNoSpace();
				break;
			case 'nonumber':
				this.checkNoNumber();
				break;
			case 'nosymbol':
				this.checkNoSymbol();
				break;
			case 'zenkaku':
				this.checkZenkaku();
				break;
			case 'hankaku':
				this.checkHankaku();
				break;
			case 'kana':
				this.checkKana();
				break;
			case 'hiragana':
				this.checkHiragana();
				break;
			case 'katakana':
				this.checkKatakana();
				break;
			case 'number':
				this.checkNumber();
				break;
			case 'alphabet':
				this.checkAlphabet();
				break;
			case 'alnum':
				this.checkAlphabetNumber();
				break;
			case 'email':
				this.checkEmail();
				break;
			case 'date':
				this.checkDate();
				break;
			case 'jancode':
				this.checkJancode();
				break;
			case 'mixpassword':
				this.checkMixPassword();
				break;
			case 'birthdate':
				this.checkBirthDate();
				break;
			case 'max':
				this.checkMax(args[1]);
				break;
			case 'min':
				this.checkMin(args[1]);
				break;
			case 'range':
				this.checkRange(args[1], args[2]);
				break;
			case 'len':
				this.checkLength(args[1]);
				break;
			case 'retype':
				this.checkRetype(args[1]);
				break;
			case 'typebase':
				this.checkTypebase(args[1]);
				break;
			case 'instagram':
				this.checkInstagram();
				break;
			case 'checkboxempty':
				this.checkCheckboxEmpty();
				break;
			case 'radioempty':
				this.checkRadioEmpty();
				break;
		}
	};


	/**
	 * エラーを表示する
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.showError = function () {
		this._common.log(this._label);
		this._controller.setLabel(this._label);
		if (!this._$e.hasClass('is-invalid')) {
			this._$e.addClass('is-invalid');
		}
		var error = '';
		for (var i = 0; i < this._error.length; i++) {
			error += this._error[i];
		}
		this._$errortipInner.html(error);
		// 1 -> -30
		var top = -18 - (this._error.length * 15 - 3);
		this._$errortip.css('top', top + 'px').addClass('show');
	};


	/**
	 * エラーを非表示にする
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.hideError = function () {
		this._$e.removeClass('is-invalid');
		this._$errortip.removeClass('show').css('top', '-30px');
		this._$errortipInner.html('');
	};


	/**
	 * 空白チェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkEmpty = function () {
		if (this._type == 'file') {
			var name = this._$e.attr('name');
			var imageUrl = $('input[name=' + name + '][type=hidden]').val() || null;
			if (this._value == '') {
				if(imageUrl) {
					this._common.log('既存画像あり');
				}else{
					this._common.log('既存画像もなし');
					this._error.push(this._errorMessage['file']);
				}
			}
		}else{
			if (this._value == '') {
				this._error.push(this._errorMessage['empty']);
			}
		}
	};


	/**
	 * スペースチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkNoSpace = function () {
		if (this._value.match(/[　\s]/)) {
			this._error.push(this._errorMessage['nospace']);
		}
	};


	/**
	 * 数字チェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkNoNumber = function () {
		if (this._value.match(/[０-９0-9]/)) {
			this._error.push(this._errorMessage['nonumber']);
		}
	};


	/**
	 * 記号チェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkNoSymbol = function () {
		if (this._value.match(/[！”＃＄％＆’（）＝～｜‘｛＋＊｝＜＞？＿－＾￥＠「；：」、。・!"#\$%&'\(\)=~\|`{\+\*}<>\?_\-\^\\@\[;:\],\.\/\^]/)) {
			this._error.push(this._errorMessage['nosymbol']);
		}
	};


	/**
	 * 全角チェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkZenkaku = function () {
		var valLength  = this._value.length;
		var byteLength = this.getByteLength();
		if (valLength * 2 !== byteLength) {
			this._error.push(this._errorMessage['zenkaku']);
		}
	};


	/**
	 * 半角チェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkHankaku = function () {
		var valLength  = this._value.length;
		var byteLength = this.getByteLength();
		if (valLength !== byteLength) {
			this._error.push(this._errorMessage['hankaku']);
		}
	};


	/**
	 * バイト数確認
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.getByteLength = function () {
		var m = encodeURIComponent(this._value).match(/%[89ABab]/g);
		return this._value.length + (m ? m.length : 0);
	};


	/**
	 * かなチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkKana = function () {
		if (!this._value.match(/^[ぁ-んーァ-ヾ０-９\s　！”＃＄％＆’（）＝～｜‘｛＋＊｝＜＞？＿－＾￥＠「；：」、。・]+$/)) {
			this._error.push(this._errorMessage['kana']);
		}
	};


	/**
	 * ひらがなチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkHiragana = function () {
		if (!this._value.match(/^[ぁ-んー０-９－\s　！”＃＄％＆’（）＝～｜‘｛＋＊｝＜＞？＿－＾￥＠「；：」、。・]+$/)) {
			this._error.push(this._errorMessage['hiragana']);
		}
	};


	/**
	 * カタカナチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkKatakana = function () {
		if (!this._value.match(/^[ァ-ヾ０-９－\s　！”＃＄％＆’（）＝～｜‘｛＋＊｝＜＞？＿－＾￥＠「；：」、。・]+$/)) {
			this._error.push(this._errorMessage['katakana']);
		}
	};


	/**
	 * 数字チェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkNumber = function () {
		if (!this._value.match(/^[0-9\s!"#\$%&'\(\)=~\|`{\+\*}<>\?_\-\^\\@\[;:\],\.\/\^]+$/)) {
			this._error.push(this._errorMessage['number']);
		}
	};


	/**
	 * アルファベットチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkAlphabet = function () {
		if (!this._value.match(/^[a-zA-Z\s!"#\$%&'\(\)=~\|`{\+\*}<>\?_\-\^\\@\[;:\],\.\/\^]+$/)) {
			this._error.push(this._errorMessage['alphabet']);
		}
	};


	/**
	 * アルファベット・数字チェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkAlphabetNumber = function () {
		if (!this._value.match(/^[0-9a-zA-Z\s!"#\$%&'\(\)=~\|`{\+\*}<>\?_\-\^\\@\[;:\],\.\/\^]+$/)) {
			this._error.push(this._errorMessage['alnum']);
		}
	};


	/**
	 * メールアドレスチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkEmail = function () {
		if (!this._$e.val().match(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i)) {
			this._error.push(this._errorMessage['email']);
		}
	};


	/**
	 * 日付チェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkDate = function () {
		if (!this._$e.val().match(/^\d{4}-\d{2}-\d{2}$/)) {
			this._error.push(this._errorMessage['date']);
		}
	};


	/**
	 * JANコードチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkJancode = function () {
		var val = this._value
		var digit = val.charAt(12);
		var even = parseInt(val.charAt(1)) + parseInt(val.charAt(3)) + parseInt(val.charAt(5)) + parseInt(val.charAt(7)) + parseInt(val.charAt(9)) + parseInt(val.charAt(11));
		var odd = parseInt(val.charAt(0)) + parseInt(val.charAt(2)) + parseInt(val.charAt(4)) + parseInt(val.charAt(6)) + parseInt(val.charAt(8)) + parseInt(val.charAt(10));
		var total = even * 3 + odd;
		total = new String(total);
		var totalLen = total.length;
		var last = parseInt(total.charAt(totalLen-1));
		var rest;
		if (last == 0){
			rest = 0;
		}else{
			rest = 10 - last;
		}
		if(rest != digit){
			this._error.push(this._errorMessage['jancode']);
		}
	};


	/**
	 * 英数混在パスワードチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkMixPassword = function () {
		if (!this._$e.val().match(/[a-zA-Z]/)) {
			this._error.push(this._errorMessage['mixPasswordAlphabet']);
		} else if (!this._$e.val().match(/[0-9]/)) {
			this._error.push(this._errorMessage['mixPasswordNumber']);
		}
	};


	/**
	 * 誕生日チェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkBirthDate = function () {
		Common.log('check birthDate');
		var val = this._$e.val();
		var max = Common.getCurrentDateTime().substr(0,10);
		var maxYear = max.substr(0,4);
		var minYear = maxYear - 80;
		var min = max.replace(maxYear, minYear);
		Common.log(min + '/' +  val + '/' + max);
		if (val > max || val < min) {
			this._error.push(this._errorMessage['birthDate']);
		}
	};


	/**
	 * 最大文字数チェック
	 *
	 * @memberof FormValidateView
	 * @param {string} max - 最大文字数
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkMax = function (max) {
		if (this._value.length > max) {
			this._error.push(this._errorMessage['max1'] + max + this._errorMessage['max2']);
		}
	};


	/**
	 * 最小文字数チェック
	 *
	 * @memberof FormValidateView
	 * @param {string} min - 最小文字数
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkMin = function (min) {
		if (this._value.length < min) {
			this._error.push(this._errorMessage['min1'] + min + this._errorMessage['min2']);
		}
	};


	/**
	 * 文字数最大最小チェック
	 *
	 * @memberof FormValidateView
	 * @param {string} min - 最小文字数
	 * @param {string} max - 最大文字数
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkRange = function (min, max) {
		if (this._value.length > max || this._value.length < min) {
			this._error.push(this._errorMessage['range1'] + min + this._errorMessage['range2'] + max + this._errorMessage['range3']);
		}
	};


	/**
	 * 文字数チェック
	 *
	 * @memberof FormValidateView
	 * @param {string} len - 文字数
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkLength = function (len) {
		if (this._value.length !== len) {
			this._error.push(this._errorMessage['len1'] + len + this._errorMessage['len2']);
		}
	};


	/**
	 * 入力確認チェック（比較先）
	 *
	 * @memberof FormValidateView
	 * @param {string} id - 比較元ID
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkRetype = function (id) {
		var target      = $('#' + id);
		var targetValue = $.trim(target.val());
		if (!targetValue || targetValue === '') return;
		if (this._value !== targetValue) {
			this._error.push(this._errorMessage['retype']);
		}
	};


	/**
	 * 入力確認チェック（比較元）
	 *
	 * @memberof FormValidateView
	 * @param {string} id - 比較先ID
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkTypebase = function (id) {
		var target      = $('#' + id);
		var targetValue = $.trim(target.val());
		if (!targetValue || targetValue === '') return;
		var validateMsgBox = target.closest('.tr-form-group').find('.tr-form-group__form__validate');
		if (this._value !== targetValue) {
			if (validateMsgBox.find('p').hasClass('retype')) return;
			validateMsgBox.append(this._errorMessage['retype']);
		} else {
			validateMsgBox.find('.retype').remove();
		}
	};


	/**
	 * Instagramチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkInstagram = function () {
		if (!this._value.match(/^[0-9a-zA-Z._]+$/)) {
			this._error.push(this._errorMessage['instagram']);
		}
	};


	/**
	 * checkbox emptyチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkCheckboxEmpty = function () {
		this._common.className('FormValidateView.prototype.checkCheckboxEmpty');
		var $formGroupContents = this._$e.closest('.form-group__contents');
		var cnt = 0;
		$formGroupContents.find('input[type=checkbox]').each(function(idx, e){
			var $e = $(e);
			console.log($e.closest('.custom-control').find('.custom-control__description').text());
			if($e.prop('checked')){
				cnt ++;
			}
		});
		if(cnt === 0){
			this._error.push(this._errorMessage['checkboxEmpty']);
		}
	};


	/**
	 * radio emptyチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.checkRadioEmpty = function () {
		this._common.className('FormValidateView.prototype.checkRadioEmpty');
		var name = this._$e.attr('name');
		console.log(name);
		var cnt = 0;
		$('input[name=' + name + ']').each(function(idx, e){
			var $e = $(e);
			console.log($e.closest('.custom-control').find('.custom-control__description').text());
			if($e.prop('checked')){
				cnt ++;
			}
		});
		if(cnt === 0){
			this._error.push(this._errorMessage['radioEmpty']);
		}
	};


	/**
	 * 郵便番号住所変換
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	FormValidateView.prototype.ajaxZip = function (e) {
		AjaxZip3.zip2addr(e, '', this._targetPrefecture, this._targetAddress);
	};


	module.exports = FormValidateView;
})(window, document, jQuery);