/**
 * @file 地図を開く
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace EventMapOpenView
	 * @constructor
	 * @classdesc 動画iframe高さ調整
	 * @param {instance} common - Commonインスタンス
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var EventMapOpenView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e = $e;
		this._$e.on('click', this.onClick.bind(this));
	};


	/**
	 * @memberof EventMapOpenView
	 * @const {string}
	 */
	EventMapOpenView._classname = 'EventMapOpenView';


	/**
	 * iframe高さ調整
	 *
	 * @memberof EventMapOpenView
	 * @return {undefined}
	 */
	EventMapOpenView.prototype.onClick = function() {
		this._common.className('EventMapOpenView.prototype.onClick');
		var address = this._$e.attr('data-address').replace(/　/ig, ' ').split(' ');
		window.open().location.href = 'https://maps.google.co.jp/maps/search/' + address[0];
	};



	module.exports = EventMapOpenView;
})(window, document, jQuery);