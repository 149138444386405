/**
 * @file 誕生日設定
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace FormBirthdayView
	 * @constructor
	 * @classdesc 誕生日設定
	 * @param {instance} common - Commonインスタンス
	 * @param {instance} controller - controllerオブジェクト
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var FormBirthdayView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e = $e;
		this._$formGroup = this._$e.closest('.form-group');
		this._$year = this._$formGroup.find('.year');
		this._$month = this._$formGroup.find('.month');
		this._$day = this._$formGroup.find('.day');
		this._value = $e.val() || null;
		setTimeout(this.init(), 500);
	};


	/**
	 * @memberof FormBirthdayView
	 * @const {string}
	 */
	FormBirthdayView._classname = 'FormBirthdayView';


	/**
	 * init
	 *
	 * @memberof FormBirthdayView
	 * @return {undefined}
	 */
	FormBirthdayView.prototype.init = function() {
		this._common.className('FormBirthdayView.prototype.init');
		var year, month, day;
		if(this._value && this._value.length >= 10) {
			year = this._value.substr(0, 4);
			month = this._value.substr(5, 2);
			day = this._value.substr(8, 2);
			this._$year.val(year);
			this._$month.val(month);
			this._$day.val(day);
		}
	};


	module.exports = FormBirthdayView;
})(window, document, jQuery);