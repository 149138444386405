/**
 * @file FAQスクロール
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace FaqFilterTypeView
	 * @constructor
	 * @classdesc FAQスクロール
	 * @param {instance} common - Commonインスタンス
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var FaqFilterTypeView = function (common, $e) {
		this._common = common;
		this._$e = $e;
		this._$e.on('change', this.onChange.bind(this));
	};


	/**
	 * @memberof FaqFilterTypeView
	 * @const {string}
	 */
	FaqFilterTypeView._classname = 'FaqFilterTypeView';


	/**
	 * 変更のコールバック
	 *
	 * @memberof FaqFilterTypeView
	 * @return {undefined}
	 */
	FaqFilterTypeView.prototype.onChange = function() {
		this._common.className('FaqFilterTypeView.prototype.onChange');
		var type = this._$e.find('option:selected').val();
		this._common.log(type);
		if(type == '') return;
		var pos = $('#' + type).get(0).offsetTop + 220;
		if($(window).width() >= 768){
			pos = pos + 40;
		}
		this._common.log($('#' + type).get(0).offsetTop);
		window.scrollTo(0, pos);
	};


	module.exports = FaqFilterTypeView;
})(window, document, jQuery);