/**
 * @file ハッシュタグコピー
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace ClubCopyHashView
	 * @constructor
	 * @classdesc ハッシュタグコピー
	 * @param {instance} common - Commonインスタンス
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var ClubCopyHashView = function (common, $e) {
		this._common = common;
		this._$e = $e;
		this._$e.on('click', this.onClick.bind(this));
	};


	/**
	 * @memberof ClubCopyHashView
	 * @const {string}
	 */
	ClubCopyHashView._classname = 'ClubCopyHashView';


	/**
	 * Clickのコールバック
	 *
	 * @memberof ClubCopyHashView
	 * @return {undefined}
	 */
	ClubCopyHashView.prototype.onClick = function() {
		this._common.className('ClubCopyHashView.prototype.onClick');
		var element = document.querySelector('#hashtags');
		var selection = window.getSelection();
		var range = document.createRange();
		range.selectNodeContents(element);
		selection.removeAllRanges();
		selection.addRange(range);
		console.log('選択された文字列: ', selection.toString());
		var succeeded = document.execCommand('copy');
		if (succeeded) {
			alert('ハッシュタグをコピーしました！');
			console.log('コピーが成功しました！');
		} else {
			console.log('コピーが失敗しました!');
		}
		selection.removeAllRanges();
	};


	module.exports = ClubCopyHashView;
})(window, document, jQuery);