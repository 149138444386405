/**
 * @file _common.js
 */
(function (window, document) {
	var instance;

	/**
	 * @namespace Form
	 * @constructor
	 * @classdesc Form Controller
	 *
	 * Singleton
	 */
	var Common = function(){
		if (instance) {
			return instance;
		}

		this.addHtmlClass();
		instance = this;
		return instance;
	};


	/* Test Mode */
	var testMode = window.wiz.const.TEST_MODE;
	var displayClass = window.wiz.const.DISPLAY_CLASS;
	var displayTrigger = window.wiz.const.DISPLAY_TRIGGER;
	var displayError = window.wiz.const.DISPLAY_ERROR;


	/**
	 * @memberof Common
	 * @const {string}
	 */
	Common.prototype._classname = 'Common';


	/**
	 * テストモードの時だけログを出力する。
	 *
	 * @memberof Common
	 * @return {undefined}
	 */
	if (testMode) {
		Common.prototype.log = console.log.bind(console, "%c%s", 'color:green;');
	} else {
		Common.prototype.log = function () {
		};
	}


	/**
	 * テストモードの時だけクラス名を出力する。
	 *
	 * @memberof Common
	 * @return {undefined}
	 */
	if (displayClass) {
		Common.prototype.className = console.log.bind(console, "%c%s", 'color:black;');
	} else {
		Common.prototype.className = function () {
		};
	}


	/**
	 * テストモードの時だけトリガー名を出力する。
	 *
	 * @memberof Common
	 * @return {undefined}
	 */
	if (displayTrigger) {
		Common.prototype.trigger = console.log.bind(console, "%c%s", 'color: orange;');
	} else {
		Common.prototype.trigger = function () {
		};
	}


	/**
	 * テストモードの時だけエラー名を出力する。
	 *
	 * @memberof Common
	 * @return {undefined}
	 */
	if (displayError) {
		Common.prototype.error = console.log.bind(console, "%c%s", 'color: red;');
	} else {
		Common.prototype.error = function () {
		};
	}


	/**
	 * ゼロパディングする
	 *
	 * @memberof Common
	 * @param {int} num - 元の数字
	 * @param {int} length - 桁数
	 * @return {string} - ゼロパディングした数字
	 */
	Common.prototype.zeroPadding = function (num, length) {
		return ('0000000000' + num).slice(-length);
	};


	/**
	 * 数字をカンマ区切りにする
	 *
	 * @memberof Common
	 * @param {string} num - 3桁区切りの数字
	 * @return {string} - カンマを削除した数字
	 */
	Common.prototype.separatedByComma = function (num) {
		return Number(num).toLocaleString();
	};


	/**
	 * 日時を取得する
	 *
	 * @memberof Common
	 * @param {date} date - 日時
	 * @return {date} date - 現在日時
	 */
	Common.prototype.getCurrentDateTime = function (date) {
		if (date) {
			date = new Date(date);
		}else{
			date = new Date();
		}
		date =
			date.getFullYear() + '-' +
			this.zeroPadding(date.getMonth() + 1, 2) + '-' +
			this.zeroPadding(date.getDate(),2) + ' ' +
			this.zeroPadding(date.getHours(), 2) + ':' +
			this.zeroPadding(date.getMinutes(), 2) + ':' +
			this.zeroPadding(date.getSeconds(), 2);
		return date;
	};



	/**
	 * 本日の日付を取得する
	 *
	 * @memberof Common
	 * @return {date} date - 現在日時
	 */
	Common.prototype.getCurrentDate = function () {
		var date = new Date();
		date =
			date.getFullYear() + '-' +
			this.zeroPadding(date.getMonth() + 1, 2) + '-' +
			this.zeroPadding(date.getDate(),2) ;
		return date;
	};


	/**
	 * 本日の年を取得する
	 *
	 * @memberof Common
	 * @return {date} year - 年
	 */
	Common.prototype.getCurrentYear = function () {
		var date = new Date();
		return date.getFullYear();
	};


	/**
	 * その月の日数を返す
	 *
	 * @memberof Common
	 * @param {integer} year - 年
	 * @param {integer} month - 月
	 * @return {integer} lastday - その月の日数
	 */
	Common.prototype.getLastDay = function(year,month){
		var lastday = ['', 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
		if (year % 4 == 0 && year % 100 != 0){
			lastday[2] = 29;
		}
		return lastday[parseInt(month)];
	};


	/**
	 * パラメーターなしのURLを取得する
	 *
	 * @memberof Common
	 * @return {string} url - パラメーターなしのURL
	 */
	Common.prototype.getCurrentURL = function () {
		var url = document.location.href;
		if (url.match(/\?/)) {
			url = RegExp.leftContext;
		} else if (url.match(/#/)) {
			url = RegExp.leftContext;
		}
		return url;
	};


	/**
	 * URLパラメーターを取得する
	 *
	 * @memberof Common
	 * @return {array} param - URLパラメーター key value
	 */
	Common.prototype.getParam = function () {
		var url = document.location.href;
		if (url.match(/#/)) {
			url = RegExp.leftContext;
		}
		if (url.match(/\?/)) {
			var params = RegExp.rightContext;
		} else {
			return {};
		}
		var tmp = params.split('&');
		var param = {};
		var tmp2, key, val;
		for (var i = 0; i < tmp.length; i++) {
			// initialize
			tmp2 = [];
			key = '';
			val = '';

			tmp2 = tmp[i].split('=');
			key = tmp2[0];
			val = tmp2[1];
			param[key] = val;
		}
		return param;
	};


	/**
	 * パラメーターを文字列で取得する
	 *
	 * @memberof Common
	 * @return {string} url - パラメーターなしのURL
	 */
	Common.prototype.getParamAll = function () {
		var url = document.location.href;
		if (url.match(/\?/)) {
			var param = RegExp.rightContext;
		}
		return param;
	};


	/**
	 * エラーモーダルを表示する
	 *
	 * @memberof Common
	 * @return {string} msg - 表示するメッセージ
	 */
	Common.prototype.showErrorModal = function (title, msg, errorMsg) {
		errorMsg = errorMsg.filter(function (x, i, self) {
			return self.indexOf(x) === i;
		});
		this.log(JSON.stringify(errorMsg));
		var errorModal =
			'<div class="error-modal">' +
			'	<div class="error-modal__dialog">' +
			'		<a class="error-modal__dialog__close"><img src="/img/close.png" alt="close"></a>' +
			'		<p class="error-modal__dialog__icon"><img src="/img/error.png" alt="error"></p>' +
			'		<p class="error-modal__dialog__title">' + title + '</p>';
		if(errorMsg.length > 0){
			errorModal += '<p class="error-modal__dialog__message">';
			for(var j = 0; j < errorMsg.length; j++){
				if(j !== 0){
					errorModal += '、';
				}
				errorModal += errorMsg[j];
			}
			errorModal += 'の入力に誤りがあります。</p>';
		}else if(msg) {
			errorModal +=
			'		<p class="error-modal__dialog__message">' + msg + '</p>';
		}
		errorModal +=
			'		<div><a href="javascript:void(0);" class="btn btn--ft-font btn--black btn--dialog__close">CLOSE</a></div>'
			'	</div>' +
			'</div>';
		$('body').append(errorModal);
		$('.error-modal__dialog__close').on('click', function(){
			$('.error-modal').remove();
		});
		$('.btn--dialog__close').on('click', function(){
			$('.error-modal').remove();
		});
	};


	/**
	 * フォームの送信をする
	 *
	 * @memberof Common
	 */
	Common.prototype.submit = function ($form) {
		if($form.attr('action') == '#' || $form.attr('action') == ''){
			this.showDummySubmit();
		}else{
			$form.submit();
		}
	};


	/**
	 * ダミーのフォーム送信モーダルを表示する
	 *
	 * @memberof Common
	 */
	Common.prototype.showDummySubmit = function () {
		swal({
			title: 'フォーム送信完了',
			content: {
				element: 'span'
			},
			icon: 'info'
		})
		$('.swal-content').html('HTMLのactionに値を設定するとフォームが送信されます。<br>実際のjavascript処理は正常に完了しています。')
	};


	/**
	 * 配列から重複なく複数の値を取り出す
	 *
	 * @memberof Common
	 * @return {array / string} r - 配列の値
	 */
	Common.prototype.getRandomFromArray = function (array, num, isString) {
		var a = array;
		var t = [];
		var r = [];
		var l = a.length;
		var n = num < l ? num : l;
		while (n-- > 0) {
			var i = Math.random() * l | 0;
			r[n] = t[i] || a[i];
			--l;
			t[i] = t[l] || a[l];
		}
		if(isString){
			return r.toString();
		}else{
			return r;
		}
	};


	/**
	 * 通信インジケーターを表示する
	 *
	 * @memberof Common
	 * @return {undefined}
	 */
	Common.prototype.showLoading = function () {
		var loading = '<div id="loading"><i class="fa fa-spinner fa-pulse fa-3x fa-fw" aria-hidden="true"></i></div>';
		$('body').append(loading);
	};


	/**
	 * 通信インジケーターを非表示にする
	 *
	 * @memberof Common
	 * @return {undefined}
	 */
	Common.prototype.hideLoading = function () {
		$('#loading').remove();
	};


	/**
	 * 機種判定を行う
	 *
	 * @memberof Common
	 * @return {undefined}
	 */
	Common.prototype.addHtmlClass = function () {
		var ua = window.navigator.userAgent.toLowerCase();
		var ver = window.navigator.appVersion.toLowerCase();

		// check browser
		var browser, device;
		if (ua.indexOf('edge') !== -1){
			browser = 'edge';
		} else if (ua.indexOf("iemobile") !== -1) {
			browser = 'iemobile';
		} else if (ua.indexOf('trident/7') !== -1) {
			browser =  'ie11';
		} else if (ua.indexOf("msie") !== -1 && ua.indexOf('opera') === -1){
			if(ver.indexOf("msie 6.")  !== -1) {
				browser = 'ie6';
			} else if (ver.indexOf("msie 7.")  !== -1) {
				browser = 'ie7';
			} else if (ver.indexOf("msie 8.")  !== -1) {
				browser = 'ie8';
			} else if (ver.indexOf("msie 9.")  !== -1) {
				browser = 'ie9';
			} else if (ver.indexOf("msie 10.") !== -1) {
				browser = 'ie10';
			}
		} else if (ua.indexOf('chrome')  !== -1 && ua.indexOf('edge') === -1) {
			browser =  'chrome';
		} else if (ua.indexOf('safari')  !== -1 && ua.indexOf('chrome') === -1) {
			browser =  'safari';
		} else if (ua.indexOf('opera')   !== -1) {
			browser = 'opera';
		} else if (ua.indexOf('firefox') !== -1) {
			browser = 'firefox';
		}

		// check device
		if(ua.indexOf('iphone') !== -1 || ua.indexOf('ipod') !== -1 ) {
			device = 'iphone';
		} else if (ua.indexOf('ipad')    !== -1) {
			device = 'ipad';
		} else if (ua.indexOf('android') !== -1) {
			device = 'android';
		} else if (ua.indexOf('windows') !== -1 && ua.indexOf('phone') !== -1) {
			device = 'windows_phone';
		}

		if(browser) $('html').addClass(browser);
		if(device) $('html').addClass(device);
	};


	module.exports = Common;
})(window, document, jQuery);
