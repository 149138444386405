/**
 * @file ファイル選択
 */
(function (window, document, $) {
	'use strict';
	var ApplyClass  = require('../util/_apply-class');
	var FormValidateView = require('./_form-validate-view');


	/**
	 * @namespace FormFileView
	 * @constructor
	 * @classdesc ファイル選択
	 * @param {instance} common - Commonインスタンス
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var FormFileView = function (common, controller, $e) {
		this._common = common;
		this._controller = controller;
		this._$e = $e;
		this._$existedFile = this._$e.closest('.form-group').find('.existed-file');
		var fileSelect = this._$e.closest('.file-select');
		var html = $('html');
		if(html.hasClass('ie11') || html.hasClass('edge')){
			$('.drop-box').remove();
		}else{
			fileSelect.remove();
		}
		this._$uploadFile = fileSelect.find('.upload-image');
		this._$deleteImg = fileSelect.find('.delete-image');
		this._$fileInfo = fileSelect.find('.file-information');
		this._$inputFileBox = fileSelect.find('.input-file-select-box');
		this._inputFileName = this._$e.attr('name');
		this._inputFileValidate = this._$e.attr('data-validate');
		this._common.log('this._inputFileValidate: ' + this._inputFileValidate);
		this._$inputFileUrl = fileSelect.find('.input-file-url');
		this._$e.on('change', this.onChange.bind(this));
		this._$deleteImg.on('click', this.onDeleteClick.bind(this));
	};


	/**
	 * @memberof FormFileView
	 * @const {string}
	 */
	FormFileView._classname = 'FormFileView';


	/**
	 * 画像選択のコールバック
	 *
	 * @memberof FormFileView
	 * @return {undefined}
	 */
	FormFileView.prototype.onChange = function(ev) {
		this._common.className('FormFileView.prototype.onChange');
		var files = ev.target.files;
		var reader = new FileReader();
		reader.onload = this.adjustExif(files);
	};


	/**
	 * EXIF処理
	 *
	 * @memberof FormFileView
	 * @param {object} file - ファイルオブジェクト
	 * @return {undefined}
	 */
	FormFileView.prototype.adjustExif = function(files) {
		this._common.className('FormFileView.prototype.adjustExif');
		var file = files[0];
		this.outputImage(files);
	};




	/**
	 * 画像の表示
	 *
	 * @memberof FormFileView
	 * @param {object} files - ファイルオブジェクト
	 * @return {undefined}
	 */
	FormFileView.prototype.outputImage = function(files) {
		this._common.className('FormFileView.prototype.outputImage');
		this._$fileInfo.addClass('mt10');
		var file = files[0];
		var image = new Image(200);
		image.src = URL.createObjectURL(file);
		this._$deleteImg.css('display', 'inline-block');
		this._$uploadFile
			.css('width', '200px')
			.css('height', '200px')
			.css('background', 'transparent url(' + image.src + ') no-repeat 50% 50%/cover')
			.css('border', '1px solid #999');
	};


	/**
	 * 画像削除ボタンクリック
	 *
	 * @memberof FormFileView
	 * @return {undefined}
	 */
	FormFileView.prototype.onDeleteClick = function() {
		this._common.className('FormFileView.prototype.onClick');
		this._$uploadFile.find('div').html('');
		this._$fileInfo.removeClass('mt10');
		this._$deleteImg.hide();
		var inputFile = '<input class="input-file-select validate" type="file" value="ファイルを選択" name="' + this._inputFileName + '" data-validate="' + this._inputFileValidate + '">';
		this._$existedFile.val('');
		this._$inputFileBox.html(inputFile);
		this._$uploadFile.removeAttr('style');
		this._$inputFile = this._$inputFileBox.find('.input-file-select');
		this._$inputFile.on('change', this.onChange.bind(this));
		ApplyClass.apply(FormValidateView, this._$inputFile, [this._common, this._controller, this._$inputFile]);
	};

	module.exports = FormFileView;
})(window, document, jQuery);