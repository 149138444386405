/**
 * @file シェア
 */
(function (window, document, $) {
	'use strict';
	var ApplyClass  = require('../util/_apply-class');
	var ShareBtnView = require('../view/_share-btn-view');

	/**
	 * シェア
	 */
	var shareInitialize = function (common) {
		$(function () {
			applyClass(common);
		});
	};


	/**
	 * クラスを適用させる
	 *
	 * @return {undefined}
	 */
	var applyClass = function (common) {
		$('.list--share .link').each(function(idx, e){
			var $e = $(e);
			ApplyClass.apply(ShareBtnView, $e, [common, $e]);
		});
	};


	module.exports = shareInitialize;
})(window, document, jQuery);