/**
 * @file Questionnaire
 */
(function (window, document) {
	'use strict';


	/**
	 * @namespace Questionnaire
	 * @constructor
	 * @param {instance} common - Commonインスタンス
	 * @param {object} controller - コントローラー
	 */
	var Questionnaire = function(common, controller){
		this._common = common;
		this._controller = controller;
	};


	/**
	 * @memberof Questionnaire
	 * @const {string}
	 */
	Questionnaire.prototype._classname = 'Questionnaire';


	/**
	 * フォーム送信前処理
	 *
	 * @memberof Questionnaire
	 * @return {undefined}
	 */
	Questionnaire.prototype.preProcessing = function($form) {
		this._common.className('FormSubmitView.prototype.preProcessing');
		// バリデーション前に行う処理を記載（開始）

		// バリデーション前に行う処理を記載（終了）
		this._controller.completePreProcessing($form);
	};


	/**
	 * フォーム送信後処理
	 *
	 * @memberof Questionnaire
	 * @return {undefined}
	 */
	Questionnaire.prototype.postProcessing = function($form) {
		this._common.className('FormSubmitView.prototype.postProcessing');
		// バリデーション後に行う処理を記載（開始）
		var questionnaire = {};
		var no = 1;
		$('.form-group__contents--additional').each(function(){
			var type = $(this).attr('data-questionnaire-type');
			var answer;
			if(type == 'input'){
				answer = $(this).find('input').val();
			}else if(type == 'textarea'){
				answer = $(this).find('textarea').val();
			}else if(type == 'select'){
				answer = $(this).find('select').val();
			}else if(type == 'radio'){
				answer = $(this).find('[type=radio]:checked').val();
			}else if(type == 'checkbox'){
				answer = $(this).find('[type=checkbox]:checked').map(function(){
					return $(this).val();
				}).get().join('<br>');
			}
			var required = false;
			var $formGroup = $(this).closest('.form-group');
			var question = $formGroup.find('.questionnaire-text').html()
				.replace(/<span class="badge badge--default">必須<\/span>/ig, '')
				.replace(/\t/ig, '')
				.replace(/<br>/ig, "\n");
			if($formGroup.find('.badge').text() == '必須') required = true;
			questionnaire['q' + no] = {
				question: question,
				answer: answer,
				required: required
			};
			no ++;
		});

		questionnaire = JSON.stringify(questionnaire);
		console.log(questionnaire);

		$('#questionnaire').val(questionnaire);

		// バリデーション後に行う処理を記載（終了）
		this._controller.completePostProcessing($form);
	};


	module.exports = Questionnaire;
})(window, document, jQuery);
