/**
 * @file マイページメニュー
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace MypageView
	 * @constructor
	 * @classdesc マイページメニュー
	 * @param {instance} common - Commonインスタンス
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var MypageView = function (common, $e) {
		this._common = common;
		this._$e = $e;
		this._$navi = $('.list--mypage-subnav');
		var that = this;
		this._$e.hover(
			function(){
				that.onMouseEnter()
			},
			function(){
				that.onMouseLeave()
			}
		);
	};


	/**
	 * @memberof MypageView
	 * @const {string}
	 */
	MypageView._classname = 'MypageView';


	/**
	 * メニューを開く
	 *
	 * @memberof MypageView
	 * @return {undefined}
	 */
	MypageView.prototype.onMouseEnter = function() {
		this._common.className('MypageView.prototype.onMouseEnter');
		this._$navi.stop().slideDown(300);
		this._$e.addClass('open');
	};



	/**
	 * メニューを閉じる
	 *
	 * @memberof MypageView
	 * @return {undefined}
	 */
	MypageView.prototype.onMouseLeave = function() {
		this._common.className('MypageView.prototype.onMouseLeave');
		this._$navi.stop().slideUp(300);
		this._$e.removeClass('open');
	};


	module.exports = MypageView;
})(window, document, jQuery);