/**
 * @file イベント
 */
(function (window, document, $) {
	'use strict';
	var Listener = require('../util/_listener');
	var EventModel = require('../model/_event-model');
	var instance;

	/**
	 * @namespace Event
	 * @constructor
	 * @classdesc イベント
	 * @param {object} common - common object
	 *
	 * Singleton
	 */
	var Event = function(common) {
		if (instance) {
			return instance;
		}
		this._common = common;
		this._listener = new Listener();

		this._model = new EventModel(common);
		this._model.on('getReviewsComplete', this.onGetReviewsComplete.bind(this));
		this._model.on('getEventcardsComplete', this.onGetEventcardsComplete.bind(this));
		this._model.on('error', this.onError.bind(this));

		instance = this;
		return instance;
	};


	/**
	 * @memberof Event
	 * @const {string}
	 */
	Event._className = 'Event';


	/**
	 * レビューを取得する
	 *
	 * @memberof Event
	 * @param {number} page - ページ
	 * @param {number} count - 取得件数
	 * @return {undefined}
	 */
	Event.prototype.getReviews = function(page, count) {
		this._common.className('Event.prototype.getReviews');
		this._model.getReviews(page, count);
	};


	/**
	 * レビュー取得完了後のコールバック
	 *
	 * @memberof Event
	 * @return {undefined}
	 */
	Event.prototype.onGetReviewsComplete = function() {
		this._common.className('Event.prototype.onGetReviewsComplete');
		this._listener.trigger('getReviewsComplete');
	};


	/**
	 * イベントカードを取得する
	 *
	 * @memberof Event
	 * @param {number} page - ページ
	 * @param {number} count - 取得件数
	 * @return {undefined}
	 */
	Event.prototype.getEventcards = function(page, count) {
		this._common.className('Event.prototype.getEventcards');
		this._model.getEventcards(page, count);
	};


	/**
	 * イベントカード取得完了後のコールバック
	 *
	 * @memberof Event
	 * @return {undefined}
	 */
	Event.prototype.onGetEventcardsComplete = function() {
		this._common.className('Event.prototype.onGetEventcardsComplete');
		this._listener.trigger('getEventcardsComplete');
	};


	/**
	 * エラーのコールバック
	 *
	 * @memberof Event
	 * @return {undefined}
	 */
	Event.prototype.onError = function() {
		this._common.className('Event.prototype.onError');
		this._listener.trigger('error');
	};


	/**
	 * listener on
	 *
	 * @memberof Event
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	Event.prototype.on = function(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listner off
	 *
	 * @memberof Event
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	Event.prototype.off = function(name, fn) {
		this._listener.off(name, fn);
	};


	module.exports = Event;
})(window, document, jQuery);