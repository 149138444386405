/**
 * @file Entry
 */
(function (window, document) {
	'use strict';


	/**
	 * @namespace Entry
	 * @constructor
	 * @param {instance} common - Commonインスタンス
	 * @param {object} controller - コントローラー
	 */
	var Entry = function(common, controller){
		this._common = common;
		this._controller = controller;
	};


	/**
	 * @memberof Entry
	 * @const {string}
	 */
	Entry.prototype._classname = 'Entry';


	/**
	 * フォーム送信前処理
	 *
	 * @memberof Entry
	 * @return {undefined}
	 */
	Entry.prototype.preProcessing = function($form) {
		this._common.className('FormSubmitView.prototype.preProcessing');
		// バリデーション前に行う処理を記載（開始）

		// バリデーション前に行う処理を記載（終了）
		this._controller.completePreProcessing($form);
	};


	/**
	 * フォーム送信後処理
	 *
	 * @memberof Entry
	 * @return {undefined}
	 */
	Entry.prototype.postProcessing = function($form) {
		this._common.className('FormSubmitView.prototype.postProcessing');
		// バリデーション後に行う処理を記載（開始）
		var birthday = $('.year').val() + '/' + $('.month').val() + '/' + $('.day').val();
		$('#birthday').val(birthday);

		// バリデーション後に行う処理を記載（終了）
		this._controller.completePostProcessing($form);
	};


	module.exports = Entry;
})(window, document, jQuery);
