/**
 * @file イベント
 */
(function (window, document, $) {
	'use strict';
	var ApplyClass  = require('../util/_apply-class');
	var Event = require('../controller/_event');
	var EventMovieIframeView = require('../view/_event-movie-iframe-view');
	var EventBtnReviewMoreView = require('../view/_event-btn-review-more-view');
	var EventBtnEventcardMoreView = require('../view/_event-btn-eventcard-more-view');
	var EventFilterPrefectureView = require('../view/_event-filter-prefecture-view');
	var EventFilterStatusView = require('../view/_event-filter-status-view');
	var EventMapOpenView = require('../view/_event-map-open-view');


	/**
	 * イベント
	 */
	var eventInitialize = function (common) {
		$(function () {
			applyClass(common);
		});
	};


	/**
	 * クラスを適用させる
	 *
	 * @return {undefined}
	 */
	var applyClass = function (common) {
		var controller = new Event(common);
		$('.movie__iframe').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(EventMovieIframeView, $e, [common, controller, $e]);
		});
		$('.btn--review-more').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(EventBtnReviewMoreView, $e, [common, controller, $e]);
		});
		$('.btn--eventcard-more').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(EventBtnEventcardMoreView, $e, [common, controller, $e]);
		});
		$('.filter--prefecture').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(EventFilterPrefectureView, $e, [common, controller, $e]);
		});
		$('.filter--status').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(EventFilterStatusView, $e, [common, controller, $e]);
		});
		$('.map-open').each(function (idx, e) {
			var $e = $(e);
			ApplyClass.apply(EventMapOpenView, $e, [common, controller, $e]);
		});
	};


	module.exports = eventInitialize;
})(window, document, jQuery);