/**
 * @file ストアTEL
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace StoreTelView
	 * @constructor
	 * @classdesc ストアTEL
	 * @param {instance} common - Commonインスタンス
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var StoreTelView = function (common, $e) {
		this._common = common;
		this._$e = $e;
		this._tel = this._$e.text();
		var ua = navigator.userAgent;
		if (ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
			this.setTelLink();
		}
	};


	/**
	 * @memberof StoreTelView
	 * @const {string}
	 */
	StoreTelView._classname = 'StoreTelView';


	/**
	 * 電話番号リンクを設定する
	 *
	 * @memberof StoreTelView
	 * @return {undefined}
	 */
	StoreTelView.prototype.setTelLink = function() {
		this._common.className('StoreTelView.prototype.setTelLink');
		var tel = this._tel.replace(/-/ig, '');
		var link = '<a href="tel:' + tel + '">' + this._tel + '</a>';
		this._$e.html(link);
	};


	module.exports = StoreTelView;
})(window, document, jQuery);