/**
 * @file Activityテンプレート
 */
(function (window, document, $) {
	'use strict';
	var instance;

	/**
	 * @namespace Activityテンプレート
	 * @constructor
	 * @classdesc ActivityTemplate
	 *
	 * Singleton
	 */
	var ActivityTemplate = function(common) {
		if (instance) {
			return instance;
		}
		this._common = common;

		instance = this;
		return instance;
	};



	/**
	 * @memberof ActivityTemplate
	 * @const {string}
	 */
	ActivityTemplate._className = 'ActivityTemplate';


	/**
	 * アクティビティー前半部分
	 *
	 * @memberof ActivityTemplate
	 * @return {undefined}
	 */
	ActivityTemplate.prototype.activity = function(data) {
		this._common.className('ActivityTemplate.prototype.activity');
		var date = data.club_activity_end_date.substr(0,7).replace(/-/ig,'/');
		var activity =
			'<div class="activity--list add-script">' +
			'	<div class="container">' +
			'		<div class="row area">' +
			'			<div class="col-12 col-md-6">' +
			'				<p class="text text--activity-title">' + date + ' 開催終了</p>' +
			'				<p class="text text--activity-theme">' + data.club_activity_title + '</p>' +
			'				<p class="mb30 text">' + data.club_activity_detail +
			'			</div>' +
			'			<div class="col-12 col-md-6">' +
			'				<div class="image-box image-box--activity-image">' +
			'					<img class="image" src="' + data.club_activity_main_image_url + '" alt="#' + data.club_activity_tag + '">' +
			'				</div>' +
			'			</div>' +
			'		</div>';
		if(data.activity_award.length > 0) {
			activity +=
			'		<div class="row">' +
			'			<div class="mt30 mt40-md w100p tac">' +
			'				<div class="ft-title ft-title--good-photo"></div>' +
			'			</div>' +
			'		</div>' +
			'		<div class="pos-r mt20 row">';
			for (var i = 0; i < data.activity_award.length; i++) {
				var activityAward = data.activity_award[i];
				activity += this.activityAward(activityAward);
			}
		}else{
			activity +=
			'		<div class="pos-r mt20 mb30 row">';
		}
		activity +=
			'			<div class="col-12 tac my30">' +
			'				<a class="btn btn--club" href="/bukatsu/activity/detail/' + data.club_activity_id + '">詳細を見る</a>' +
			'			</div>' +
			'		</div>' +
			'	</div>' +
			'</div>';
		return activity;
	};


	/**
	 * アクティビティーgood photo部分
	 *
	 * @memberof ActivityTemplate
	 * @return {undefined}
	 */
	ActivityTemplate.prototype.activityAward = function(activityAward) {
		this._common.className('ActivityTemplate.prototype.activityAward');
		//console.log(activityAward);
		var instagram = '';
		if(activityAward.award_image_url && activityAward.award_image_url !== ''){
			instagram +=
				'			<div class="instagram mb20">' +
				'				<p class="tac text text--good-photo-prize">' + activityAward.award_string + '</p>' +
				'				<p class="tac mb10 text text--good-photo-name">' +
				'					<a class="link" href="https://www.instagram.com/' + activityAward.account.instagram_name + '/">' + activityAward.account.instagram_name + '</a>' +
				'				</p>' +
				'				<div class="instagram__wrapper">' +
				'					<a href="' + activityAward.award_tag + '" class="instagram__wrapper__inner--photo" style="background-image: url(' + activityAward.award_image_url + ');" target="_blank"></a>' +
				'				</div>' +
				'				<p class="mt10 text">' + activityAward.award_text + '</p>' +
				'			</div>';
			return instagram;
		}else{
			var tag = activityAward.award_tag.replace(/<script +.*?>.*?<\/script>/, '');
			this._common.log(tag);
			instagram +=
				'			<div class="instagram mb20">' +
				'				<p class="tac text text--good-photo-prize">' + activityAward.award_string + '</p>' +
				'				<p class="tac mb10 text text--good-photo-name">' +
				'					<a class="link" href="https://www.instagram.com/' + activityAward.account.instagram_name + '/">' + activityAward.account.instagram_name + '</a>' +
				'				</p>' +
				'				<div class="instagram__wrapper">' +
				'					<div class="instagram__wrapper__inner">' + tag + '</div>' +
				'				</div>' +
				'				<p class="mt10 text">' + activityAward.award_text + '</p>' +
				'			</div>';
			return instagram;
		}
	};

	module.exports = ActivityTemplate;
})(window, document, jQuery);