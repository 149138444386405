/**
 * @file マイページメニュー
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace MenuView
	 * @constructor
	 * @classdesc マイページメニュー
	 * @param {instance} common - Commonインスタンス
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var MenuView = function (common, $e) {
		this._common = common;
		this._$e = $e;
		this._$navi = $('.list--header-login');
		this._$navMenu = $e.parent();
		this._$lineTop = $e.find('.menu__top');
		this._$lineMiddle = $e.find('.menu__middle');
		this._$lineBottom = $e.find('.menu__bottom');
		this._$e.on('click', this.onClick.bind(this));
	};


	/**
	 * @memberof MenuView
	 * @const {string}
	 */
	MenuView._classname = 'MenuView';


	/**
	 * メニューを開く
	 *
	 * @memberof MenuView
	 * @return {undefined}
	 */
	MenuView.prototype.onClick = function() {
		this._common.className('MenuView.prototype.openMenu');
		if(!this._$e.hasClass('open')){
			this._$navi.animate({right: '0'}, 300);
			this._$navMenu.animate({marginLeft: '10px'}, 300);
			this._$e.addClass('open');
		}else{
			this._$navi.animate({right: '-185px'}, 300);
			this._$navMenu.animate({marginLeft: '-55px'}, 300);
			this._$e.removeClass('open');
		}
	};


	/**
	 * メニューを閉じる
	 *
	 * @memberof MenuView
	 * @return {undefined}
	 */
	MenuView.prototype.closeMenu = function() {
		this._common.className('MenuView.prototype.closeMenu');
		this._$navi.animate({right: '-185px'});
	};


	module.exports = MenuView;
})(window, document, jQuery);