/**
 * @file ラジオボタン
 */
(function (window, document, $) {
	'use strict';


	/**
	 * @namespace FormRadioView
	 * @constructor
	 * @classdesc ラジオボタン
	 * @param {instance} common - Commonインスタンス
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	var FormRadioView = function (common, $e) {
		this._common = common;
		this._$e = $e;
		this._name = $e.find('input').attr('name');
		this._$radio = $('input[name=' + this._name + ']');
		this._$label = this._$radio.closest('.custom-control--radio');
		this._$formGroup = $e.closest('.form-group');
		this._$e.on('click', this.onClick.bind(this));
	};


	/**
	 * @memberof FormRadioView
	 * @const {string}
	 */
	FormRadioView._classname = 'FormRadioView';


	/**
	 * clickのコールバック
	 *
	 * @memberof FormRadioView
	 * @return {undefined}
	 */
	FormRadioView.prototype.onClick = function() {
		this._common.className('FormRadioView.prototype.onClick');
		this._$label.removeClass('checked').removeClass('is-invalid');
		this._$formGroup.find('.error-tip').removeClass('show').css('top', '-30px').find('.error-tip-inner').text('');
		this._$e.addClass('checked');
	};


	module.exports = FormRadioView;
})(window, document, jQuery);