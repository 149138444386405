/**
 * @file レビューテンプレート
 */
(function (window, document, $) {
	'use strict';
	var instance;


	/**
	 * @namespace レビューテンプレート
	 * @constructor
	 * @classdesc ReviewTemplate
	 *
	 * Singleton
	 */
	var ReviewTemplate = function(common) {
		if (instance) {
			return instance;
		}
		this._common = common;

		instance = this;
		return instance;
	};



	/**
	 * @memberof ReviewTemplate
	 * @const {string}
	 */
	ReviewTemplate._className = 'ReviewTemplate';


	/**
	 * 質問ブロック
	 *
	 * @memberof ReviewTemplate
	 * @return {undefined}
	 */
	ReviewTemplate.prototype.review = function(data) {
		this._common.className('ReviewTemplate.prototype.review');
		var review =
			'<li class="list__item">' +
			'	<div class="review-header cf">' +
			'		<span class="review review--' + data.review_rating + '"></span>' +
			'		<span class="post-user">' + data.attributes + '</span>' +
			'	</div>' +
			'	<p>' + data.review_text + '</p>'
			'</li>';
		return review;
	};


	module.exports = ReviewTemplate;
})(window, document, jQuery);