/**
 * @file Observer / Listener 系の処理
 */
(function(window, document, $){
	'use strict';
	var Common = require('./_common.js')();


	/**
	 * @namespace Listener
	 * @constructor
	 * @classdesc Observer / Listner 系、on off trigger を行う
	 */
	var Listener = function() {
		this._functions = {};
	};


	/**
	 * @memberof Listener
	 * @const {string}
	 */
	Listener.prototype._classname = 'Listener';


	/**
	 * on
	 *
	 * @memberof Listener
	 * @param {string} name - イベント名
	 * @param {function} fn - 関数
	 * @return {object} - Listener インスタンス
	 */
	Listener.prototype.on = function(name, fn) {
		// console.log('on ' + name);
		this._functions[name] = this._functions[name] || [];
		this._functions[name].push(fn);

		return this;
	};


	/**
	 * off
	 *
	 * @memberof Listener
	 * @param {string} name - イベント名
	 * @param {function} fn - 関数
	 * @return {object} - Listener インスタンス
	 */
	Listener.prototype.off = function(name, fn) {
		// console.log('off');
		var functions = this._functions[name];
		if (!functions) {
			return this;
		}
		for (var i = 0, l = functions.length; i < l; i++) {
			var _fn = functions[i];
			if (fn == _fn) {
				functions.splice(i, 1);
				break;
			}
		}
		return this;
	};


	/**
	 * Trigger
	 * @param {string} name - 送信するイベント名
	 * @return {object} - Listener インスタンス
	 */
	Listener.prototype.trigger = function(name) {
		Common.trigger('trigger: ' + name);
		var functions = this._functions[name] || [];
		for (var i = 0, l = functions.length; i < l; ++i) {
			var fn = functions[i];
			if (fn) {
				fn.apply(this, arguments);
			}
		}

		return this;
	};


	module.exports = Listener;
})(window, document, jQuery);
